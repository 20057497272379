const apiUrl = process.env.REACT_APP_API_URL || 'https://wms.api.evvosports.com';


// const apiUrl = 'http://localhost:3001';

export const azureLogin = `${apiUrl}/api/v1/auth`;
export const createTraining = `${apiUrl}/api/v1/training`;
export const Training = `${apiUrl}/api/v1/training`;
export const fetchDepartments = `${apiUrl}/api/v1/training`;
export const fetchTrainingListByDateRange = `${apiUrl}/api/v1/training`;
export const groupSync = `${apiUrl}/api/v1/training/groups-sync`;

/** users */
export const fetchUsersDetails = `${apiUrl}/api/v1/auth`;
export const syncAzureUserApi = `${apiUrl}/api/v1`;
export const generateInviteCode = `${apiUrl}/api/v1/auth`;


/**assessment  */
export const assessmentUrl = `${apiUrl}/api/v1/assessment`;
export const assessmentQuestnUrl = `${apiUrl}/api/v1/assessment-questn`;
