import { createSlice } from "@reduxjs/toolkit";
import { authSignUp } from "./authService";

const initialState = {
  data: {},
  error: {},
  loginData: {},
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearData: (state: any) => {
      state.data = {};
      state.error = null;
    },
    setLoginData: (state, data) => {
      state.loginData = data.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // SIGN UP
      .addCase(authSignUp.pending, (state: any) => {
        state.loading = true;
      })
      .addCase(authSignUp.fulfilled, (state: any, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(authSignUp.rejected, (state: any, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Action creators are generated for each case reducer function
export const { clearData, setLoginData } = authSlice.actions;

export default authSlice.reducer;
