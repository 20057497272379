import { Card, Col, Row } from "@govtechsg/sgds-react";
import { useState, useEffect } from 'react';
import SideNavBar from "../../components/Sidebar-left/sideBar";
import TopNav from "../../components/top-nav";
import Footer from "../../components/footer";
import { Dropdown, Form, Modal, Button, Spinner, Table, Badge, Pagination } from "react-bootstrap";
import Select from 'react-select';
import axios from "axios";
import { fetchUsersDetailApi, requestNewInviteCode, syncAzureData, fetchDepartment } from '../../service/common.service';
import { setInitialName } from "../../app/features/sidebarSlice"; // Import the action from sideNavSlice
import { useDispatch, useSelector } from 'react-redux';
import { isTokenValid } from "../../utils/TokenValid";
import { useNavigate } from "react-router-dom";


const GRAPH_API_BASE_URL = "https://graph.microsoft.com/v1.0";

const options = [
  { value: 5, label: '05' },
  { value: 10, label: '10' },
  { value: 50, label: '50' },
  { value: 100, label: '100' }
];


const options2 = [
  { value: 'Office Admins', label: 'Office Admins' },
  { value: 'Office User', label: 'Office User' }
]

// Sample users data
const usersData = {
  'Office Admins': [
    { id: 1, name: 'Admin User 1' },
    { id: 2, name: 'Admin User 2' },
  ],
  'Office Users': [
    { id: 3, name: 'User 1' },
    { id: 4, name: 'User 2' },
  ],
};

const UserManagement = () => {
  const [selectedGroup, setSelectedGroup] = useState<any>(null);
  const [groups, setGroups] = useState<any[]>([]);
  const [groupMembers, setGroupMembers] = useState<any>({});
  const [users, setUsers] = useState([])
  const [metaData, setMetaData] = useState({
    totalItems: 0,
    itemsCount: 0,
    itemsPerPage: 10,
    totalPages: 0,
    currentPage: 1,
    activeUsers: 0,
    inactiveUsers: 0
  });
  const [error, setError] = useState([])
  const [searchTerm, setSearchTerm] = useState('');
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState<{ label: string; value: number }>({
    label: '',
    value: 0,
  });
  const [loading, setLoading] = useState(0);
  const [userLoading, setUserLoading] = useState(0);
  const [importError, setImportError] = useState<string | null>(null);
  const [inviteCodeResponse, setInviteCodeResponse] = useState();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [sortConfig, setSortConfig] = useState<{ key: string, direction: string }>({ key: '', direction: '' });
  const [isUserImportSuccessful, setIsUserImportSuccessful] = useState(false);
  const [inviteDetails, setInviteDetails] = useState({
    userName: '',
    inviteCode: '',
  });
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [limit, setLimit] = useState(10);
  const [selectedLimit, setSelectedLimit] = useState(options[1]); // Set the initial selected option
  const dispatch = useDispatch();
  const activeItem = useSelector((state: any) => state.sidebar.initialName);
  const navigate = useNavigate();



  const handleSort = (key: string) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };


  const sortedUsers = [...users].sort((a, b) => {
    if (sortConfig.key) {
      const aVal = a[sortConfig.key] || '';
      const bVal = b[sortConfig.key] || '';

      if (sortConfig.direction === 'ascending') {
        return aVal > bVal ? 1 : -1;
      } else {
        return aVal < bVal ? 1 : -1;
      }
    }
    return 0;
  });

  const handleCloseSuccessModal = () => setShowSuccessModal(false);
  const handleCloseInviteModal = () => setShowInviteModal(false);

  const handleGroupChange = (selectedOption: any) => {
    setSelectedGroup(selectedOption);
  };

  const handleClearAll = () => {
    console.log('clicked...');
    setSelectedDepartment({ value: 0, label: "Select Department" });
    setSearchTerm('')
    setLimit(10);
    setSelectedLimit(options[1]);

    // Trigger fetchUserDetails by resetting the currentPage or other dependencies
    setMetaData((prevMeta) => ({
      ...prevMeta,
      currentPage: 1 // Reset to the first page after clearing filters
    }));

  };

  // Function to fetch group details
  const fetchGroups = async (accessToken: string) => {
    try {
      const groupResponse = await axios.get(
        `${GRAPH_API_BASE_URL}/me/memberOf`, // Fetch user's group memberships
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const groupsData = groupResponse.data.value.map((group: any) => ({
        id: group.id,
        displayName: group.displayName,
        mail: group.mail,
      }));

      console.log(groupsData, '-<,groupsData');

      setGroups(groupsData);

      // Set default selected group to the first group if available
      if (groupsData.length > 0) {
        const defaultGroup = groupsData[0]; // Select the first group as default
        setSelectedGroup({ value: defaultGroup.id, label: defaultGroup.displayName });
        fetchGroupMembers(accessToken, defaultGroup.id); // Fetch members for the default group
      }

      // Fetch members for each group
      groupsData.forEach((group: any) => {
        fetchGroupMembers(accessToken, group.id); // Fetch group members
      });
    } catch (error) {
      console.error("Error fetching groups: ", error);
    }
  };

  // Function to fetch group members by groupId
  const fetchGroupMembers = async (accessToken: string, groupId: string) => {
    try {
      const membersResponse = await axios.get(
        `${GRAPH_API_BASE_URL}/groups/${groupId}/members`, // Fetch members of the group
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const members = membersResponse.data.value.map((member: any) => ({
        id: member.id,
        displayName: member.displayName,
        mail: member.mail,
        userPrincipalName: member.userPrincipalName,
      }));

      setGroupMembers((prevMembers: any) => ({
        ...prevMembers,
        [groupId]: members, // Store members for the groupId
      }));
    } catch (error) {
      console.error(`Error fetching members for group ${groupId}: `, error);
    }
  };


  const fetchUserDetails = async () => {
    setUserLoading(1);
    try {

      // console.log(metaData.currentPage, '-<< metaData.currentPage');

      const requestPayload = {
        page: metaData.currentPage,
        limit: metaData.itemsPerPage,
        search: searchTerm,
        departmentId: selectedDepartment.value || 0
      };

      if (limit) {
        requestPayload.limit = limit;
      }

      // console.log(requestPayload, '-<<requestPayload')
      const response: any = await fetchUsersDetailApi(requestPayload);

      // console.log(response?.data, '-<<response');

      if (response?.status == 200) {
        setUsers(response?.data?.data?.listRecords)
        console.log('response?.data?.data?.meta>>')
        setMetaData(response?.data?.data?.meta)
        // console.log(users, '-<,users...');
        // console.log(metaData, '-<,metaData...');

        setUserLoading(0);
      } else if (response?.status == 400) {
        setError(response?.data?.message || 'error');
      } else {
        setError(response?.data?.message || 'error');
      }

    } catch (error) {
      console.error("Error fetching users list:", error);
    } finally {
      setUserLoading(0); // End loading
    }
  };


  const fetchDepartmentsList = async () => {
    try {
      const response: any = await fetchDepartment();

      if (response?.status == 200) {
        setDepartments(response?.data?.data)

      } else if (response?.status == 400) {
        setError(response?.data?.message || 'error');
      } else {
        setError(response?.data?.message || 'error');
      }

    } catch (error) {
      console.error("Error fetching coach list:", error);
    }
  };


  const importUsers = async () => {
    try {
      setLoading(0); // Start loading
      setImportError(null); // Reset error state

      let accessToken = localStorage.getItem("accessToken"); // Retrieve data from local storage
      // accessToken = accessToken ? JSON.parse(accessToken) : null; // Parse only if not null

      // console.log(accessToken, '-<<accessToken')

      const reqPayload = {
        accessToken: accessToken
      }
      // console.log(reqPayload, '-<<reqPayload')

      const response: any = await syncAzureData(reqPayload);

      if (response?.status == 200) {
        // setDepartments(response?.data?.data)
        // console.log('sync success');
        // alert('Successfully imported users'); // Success alert
        setShowSuccessModal(true);
        setIsUserImportSuccessful(true);

      } else if (response?.status == 400) {
        // setError(response?.data?.message || 'error');
        console.log('sync failed');
        alert('Token expired, please login again.'); // Alert for expired token

      } else {
        // setError(response?.data?.message || 'error');
        console.log('sync failed');
        alert('An unexpected error occurred.'); // Alert for unexpected errors

      }

    } catch (error) {
      console.error("Error fetching coach list:", error);
      alert('An error occurred while importing users.'); // Alert for catch block errors

    } finally {
      setLoading(0); // End loading
    }
  };

  const handleImport = async () => {
    importUsers()
  }

  // Effect to fetch user details when currentPage changes
  useEffect(() => {
    if (searchTerm.length === 0 || searchTerm.length >= 1) {
      fetchUserDetails();
    }
  }, [metaData.currentPage, searchTerm, selectedDepartment, inviteCodeResponse, isUserImportSuccessful, limit]);


  const startItem = (metaData.currentPage - 1) * metaData.itemsPerPage + 1;
  const endItem = Math.min(metaData.currentPage * metaData.itemsPerPage, metaData.totalItems);

  // Handle page change
  const handlePageChange = (page: number) => {
    setMetaData((prevMeta) => ({
      ...prevMeta,
      currentPage: page,
    }));
  };

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchTerm('');
  };

  const handleSearch = () => {
    if (searchTerm.length >= 1) {
      setMetaData((prevMeta) => ({
        ...prevMeta,
        currentPage: 1, // Reset to first page on new search
      }));
    }
  };

  const handleDepartmentChange = (selectedOption: any) => {
    setSelectedDepartment(selectedOption); // Update selected department
    console.log(selectedOption.value, '- selected department id');
  };


  const handleSelectChange = (selectedOption: any) => {
    // console.log('Selected value:', selectedOption.value);
    setSelectedLimit(selectedOption);
    setLimit(selectedOption.value);

  };


  const handleRegenerateInviteCode = async (user: any) => {
    try {
      const payload = {
        userId: user.userId
      }


      const response: any = await requestNewInviteCode(payload);

      if (response?.status == 200) {
        console.log(response.data.data, '-<<response.data.data');

        const { newInviteCode } = response?.data?.data;

        // Set invite details
        setInviteDetails({
          userName: user.userName,
          inviteCode: newInviteCode
        });

        setInviteCodeResponse(response?.data?.data)
        setShowInviteModal(true); // Show modal after success


      } else if (response?.status == 400) {
        setError(response?.data?.message || 'error');
      } else {
        setError(response?.data?.message || 'error');
      }

    } catch (error) {
      console.error("Error fetching coach list:", error);
    }
  };


  // Fetch groups when the component mounts
  useEffect(() => {

    dispatch(setInitialName("User Management"));

    const accessToken = localStorage.getItem("accessToken");

    console.log(accessToken, '-<<accessToken')
    if (!accessToken) {
      navigate("/auth");
    }
    // console.log(accessToken, '0<<accessToken')
    // Check if token is valid on initial load
    if (!isTokenValid(accessToken)) {
      // If token is invalid, redirect to login
      navigate("/auth");
    }

    if (accessToken) {
      fetchGroups(accessToken);
    }
    fetchUserDetails()
    fetchDepartmentsList()
  }, [dispatch]);

  return (
    <>
      <TopNav />
      <div className="d-flex align-items-stretch">
        <SideNavBar />


        <div className="page-content pb-4">
          <div className="bredcrumb">
            <ul>
              <li><a href="#">Home</a></li>
              <li>User Management</li>
            </ul>
          </div>
          <section className="blockWrap no-padding-bottom p-0">
            <div className="card">
              <div className="card-header d-flex align-items-center justify-content-between border-bottom mb-2">
                {/* Button aligned to the left */}
                <div className="pageTitle">
                  <h5>User Management</h5>
                </div>
                {/* <a
                  href="#"
                  className="btn btn-primary mn-w-148"
                  onClick={(e) => {
                    e.preventDefault();
                    handleImport();
                  }}
                >
                  {loading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Import Users" // Show button text when not loading
                  )}
                </a> */}

                <button
                  type="button" // Use "button" to prevent it from submitting a form if inside one
                  className="btn btn-primary mn-w-148"
                  onClick={(e) => {
                    e.preventDefault();
                    handleImport();
                  }}
                >
                  {loading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Import Users" // Show button text when not loading
                  )}
                </button>


                {/* Display loading and error messages */}
              </div>
              <div className="ml-3"> {/* Added margin-left for spacing */}
                {error && <p className="error mb-0">{error}</p>} {/* Error message */}
              </div>

              <div className="card-body pt-0">
                <Row>
                  <Col lg="3">
                    <div className="userCountBlock">
                      <div className="cont">
                        <div className="label">Total User</div>
                        <div className="count"> {metaData.totalItems} </div>
                      </div>
                      <div className="icon purple"><i className="icon-uesr-outline"></i></div>
                    </div>
                  </Col>
                  <Col lg="3">
                    <div className="userCountBlock">
                      <div className="cont">
                        <div className="label">Active Users</div>
                        <div className="count">{metaData.activeUsers}</div>
                      </div>
                      <div className="icon success"><i className="icon-uesr-outline"></i></div>
                    </div>
                  </Col>
                  {/* <Col lg="3">
                    <div className="userCountBlock">
                      <div className="cont">
                        <div className="label">Injured Users</div>
                        <div className="count">{metaData.inactiveUsers}</div>
                      </div>
                      <div className="icon danger"><i className="icon-uesr-outline"></i></div>
                    </div>
                  </Col> */}
                </Row>

                <div className="card-tableFilter">
                  {/* <div className="pageFilter">
                    <label>Show</label>
                    <div className="custom-select-wrap">
                      <Select className="selectpicker" placeholder="10" options={options} onChange={handleSelectChange} />
                    </div>
                  </div> */}

                  <div className="pageFilter">
                    <label>Show</label>
                    <div className="custom-select-wrap">
                      {/* <Select
                        className="selectpicker"
                        placeholder={limit}
                        options={options}
                        onChange={handleSelectChange} // Pass the handler function here
                      /> */}
                      <Select
                        className="selectpicker"
                        value={selectedLimit} // Bind the value to the limit state
                        options={options}
                        onChange={handleSelectChange} // Pass the handler function here
                      />

                    </div>
                  </div>

                  <div style={{ marginLeft: '16px' }}>

                    <button
                      className="btn btn-md btn-secondary ml-3"
                      onClick={handleClearAll} // Define the function to handle clearing
                    >
                      Clear All
                    </button>

                  </div>
                  <div className="tableFilterWrap">
                    <div className="custom-select-wrap">
                      <Select
                        className="selectpicker"
                        placeholder="Select Department" // Set a default placeholder
                        options={departments.map((department: any) => ({
                          value: department.id,
                          label: department.departmentName, // Ensure this is correct
                        }))}
                        onChange={handleDepartmentChange} // Handle department selection
                        value={selectedDepartment.value !== 0 ? selectedDepartment : null} // Show selected department or null
                      />

                    </div>
                    <div className="searchFilter">
                      <div className="searchBar">
                        <input
                          type="text"
                          placeholder="Search By User"
                          value={searchTerm}
                          onChange={handleSearchInput}
                        />
                        <button className="searchBtn" onClick={handleSearch}>
                          <i className="icon-search-sign"></i>
                        </button>
                        {searchTerm && (
                          <span className="searchClear icon-popup-cross" onClick={handleClearSearch}></span>
                        )}                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-table">
                  <div className="table-scroll">
                  <Table>
                    <thead>
                      <tr>
                        <th onClick={() => handleSort('userId')}>
                          <div className="thWrap">USER ID
                            <span className="orderSpan">
                              <span className={sortConfig.key === 'userId' && sortConfig.direction === 'ascending' ? 'ass active' : 'ass'}></span>
                              <span className={sortConfig.key === 'userId' && sortConfig.direction === 'descending' ? 'des active' : 'des'}></span>
                            </span>
                          </div>
                        </th>
                        <th onClick={() => handleSort('userName')}>
                          <div className="thWrap">
                            USER NAME
                            <span className="orderSpan">
                              <span className={sortConfig.key === 'userName' && sortConfig.direction === 'ascending' ? 'ass active' : 'ass'}></span>
                              <span className={sortConfig.key === 'userName' && sortConfig.direction === 'descending' ? 'des active' : 'des'}></span>
                            </span>
                          </div>
                        </th>
                        <th onClick={() => handleSort('inviteCode')}>
                          <div className="thWrap">INVITE CODE
                            <span className="orderSpan">
                              <span className={sortConfig.key === 'inviteCode' && sortConfig.direction === 'ascending' ? 'ass active' : 'ass'}></span>
                              <span className={sortConfig.key === 'inviteCode' && sortConfig.direction === 'descending' ? 'des active' : 'des'}></span>
                            </span>
                          </div>
                        </th>
                        <th onClick={() => handleSort('inviteCodeExpire')}>
                          <div className="thWrap">INVITE CODE EXPIRE
                            <span className="orderSpan">
                              <span className={sortConfig.key === 'inviteCodeExpire' && sortConfig.direction === 'ascending' ? 'ass active' : 'ass'}></span>
                              <span className={sortConfig.key === 'inviteCodeExpire' && sortConfig.direction === 'descending' ? 'des active' : 'des'}></span>
                            </span>
                          </div>
                        </th>
                        <th onClick={() => handleSort('departmentName')}>
                          <div className="thWrap">DEPARTMENT NAME
                            <span className="orderSpan">
                              <span className={sortConfig.key === 'departmentName' && sortConfig.direction === 'ascending' ? 'ass active' : 'ass'}></span>
                              <span className={sortConfig.key === 'departmentName' && sortConfig.direction === 'descending' ? 'des active' : 'des'}></span>
                            </span>
                          </div>
                        </th>
                        <th onClick={() => handleSort('status')}>
                          <div className="thWrap">STATUS
                            <span className="orderSpan">
                              <span className={sortConfig.key === 'status' && sortConfig.direction === 'ascending' ? 'ass active' : 'ass'}></span>
                              <span className={sortConfig.key === 'status' && sortConfig.direction === 'descending' ? 'des active' : 'des'}></span>
                            </span>
                          </div>
                        </th>
                        <th>ACTIONS</th>
                      </tr>

                    </thead>
                    <tbody>
                      {userLoading == 1 ? (
                        <div className="pageLoader">
                          <span className="spinner"></span>
                        </div>
                      ) : (
                        Array.isArray(sortedUsers) && sortedUsers.length > 0 ? (
                          sortedUsers.map((user: any) => (
                            <tr key={user.userId}>
                              <td>{user.userId}</td>
                              {/* <td>{user.userName || 'No Name'}</td> */}
                              {/* <td>{user.email || 'No Email'}</td> Added Email Column */}

                              <td>
                                {user.userName || 'No Name'}
                                {user.email && <div className="tdShortEmail">{user.email || 'No Email'}</div>} {/* Email displayed below userName */}
                              </td>
                              <td>{user.inviteCode || 'No Invite Code'}</td>
                              <td>
                                {user.inviteCodeExpire
                                  ? new Date(user.inviteCodeExpire).toLocaleString()
                                  : 'No Expiration'}
                              </td>
                              <td>{user.departmentName || 'No Department'}</td>
                              <td>
                                {user.injuryStatus === '1' ? (
                                  <Badge bg="danger" className="mn-w-62">Injured</Badge>
                                ) : (
                                  <Badge bg={user.azureTokenExp ? 'danger' : 'success'} className="mn-w-62">
                                    {user.azureTokenExp ? 'Inactive' : 'Active'}
                                  </Badge>
                                )}
                              </td>
                              <td>
                                <Dropdown>
                                  <Dropdown.Toggle variant="secondary" id={`dropdown-${user.userId}`} size="sm" />
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      onClick={() => handleRegenerateInviteCode(user)}
                                      disabled={new Date(user.inviteCodeExpire) > new Date()}
                                    >
                                      Regenerate Invite Code
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={7}>No users found.</td>
                          </tr>
                        )
                      )}


                    </tbody>
                  </Table>
                  </div>
                </div>
                <div className="mt-4 repoCardFoot mb-2 justify-content-between d-flex align-items-center">
                  <div className="showingContNo">
                    Showing {startItem} to {endItem} of {metaData.totalItems} entries
                  </div>
                  <Pagination className="mb-0">
                    {/* First page button */}
                    <Pagination.First
                      className="first"
                      onClick={() => handlePageChange(1)}
                      disabled={metaData.currentPage === 1}
                    />

                    {/* Previous page button */}
                    <Pagination.Prev
                      className="prev"
                      onClick={() => handlePageChange(metaData.currentPage - 1)}
                      disabled={metaData.currentPage === 1}
                    />

                    {/* Always show the first page */}
                    <Pagination.Item
                      active={metaData.currentPage === 1}
                      onClick={() => handlePageChange(1)}
                    >
                      {1}
                    </Pagination.Item>

                    {/* Show ellipsis if current page is beyond page 3 */}
                    {metaData.currentPage > 3 && <Pagination.Ellipsis />}

                    {/* Show up to 2 pages before and 2 after the current page */}
                    {Array.from({ length: 5 })
                      .map((_, index) => metaData.currentPage - 2 + index) // Generate up to 5 pages: two before and two after
                      .filter(
                        (pageNumber) =>
                          pageNumber > 1 && pageNumber < metaData.totalPages // Ensure valid page numbers between first and last
                      )
                      .map((pageNumber) => (
                        <Pagination.Item
                          key={pageNumber}
                          active={pageNumber === metaData.currentPage}
                          onClick={() => handlePageChange(pageNumber)}
                        >
                          {pageNumber}
                        </Pagination.Item>
                      ))}

                    {/* Show ellipsis if there are more than 3 pages remaining */}
                    {metaData.currentPage < metaData.totalPages - 2 && <Pagination.Ellipsis />}

                    {/* Always show the last page */}
                    {metaData.totalPages > 1 && (
                      <Pagination.Item
                        active={metaData.currentPage === metaData.totalPages}
                        onClick={() => handlePageChange(metaData.totalPages)}
                      >
                        {metaData.totalPages}
                      </Pagination.Item>
                    )}

                    {/* Next page button */}
                    <Pagination.Next
                      className="next"
                      onClick={() => handlePageChange(metaData.currentPage + 1)}
                      disabled={metaData.currentPage === metaData.totalPages}
                    />

                    {/* Last page button */}
                    <Pagination.Last
                      className="last"
                      onClick={() => handlePageChange(metaData.totalPages)}
                      disabled={metaData.currentPage === metaData.totalPages}
                    />
                  </Pagination>

                </div>
              </div>
            </div>
          </section>
          <Footer />
        </div>

        {/* Import button with loading spinner
        <Button className="btn btn-primary mn-w-148" onClick={handleImport} disabled={loading}>
          {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Import Users"}
        </Button> */}

        {/* Success modal */}
        <Modal className="mw-630" show={showSuccessModal} onHide={handleCloseSuccessModal}>
          <Modal.Header closeButton>
            <Modal.Title>Import Successful</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="successBlock">
              <div className="ico">
                <i className="icon-success"></i>
              </div>
              <div className="cont">
                <p>Users have been successfully imported.</p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="justify-content-center bt-0 pt-0 pb-4">
            <Button variant="primary" onClick={handleCloseSuccessModal}>Okay</Button>
          </Modal.Footer>
        </Modal>


        {/* Modal for displaying invite code details */}
        <Modal className="mw-630" show={showInviteModal} onHide={handleCloseInviteModal}>
          <Modal.Header closeButton>
            <Modal.Title>Invite Code Regenerated</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="successBlock">
              <div className="ico">
                <i className="icon-success"></i>
              </div>
              <div className="cont">
                <p>Invite code has been regenerated successfully.</p>
              </div>
            </div>
            <div className="teBlock mt-2">
              <div className="cont">
                <div className="label">User Name</div>
                <div className="value fw-700">{inviteDetails.userName || 'N/A'}</div>
              </div>
              <div className="cont">
                <div className="label">Invite Code</div>
                <div className="value fw-700">{inviteDetails.inviteCode || 'N/A'}</div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="justify-content-center bt-0 pt-0 pb-4">
            <Button variant="primary" onClick={handleCloseInviteModal}>Okay</Button>
          </Modal.Footer>
        </Modal>

      </div>
    </>
  );
};

export default UserManagement;