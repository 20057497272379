import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  initialName: "", // default active item
};

export const sideNavSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    setInitialName: (state, action) => {
      state.initialName = action.payload; // set the active item
    },
  },
});

// Export the action to be used in the component
export const { setInitialName } = sideNavSlice.actions;

// Export the reducer to be used in the store
export default sideNavSlice.reducer;



// import { createSlice } from "@reduxjs/toolkit";

// const initialState = {
//   initialName: "",
// };

// export const sidebarSlice = createSlice({
//   name: "sidebar",
//   initialState,
//   reducers: {
//     setInitialName: (state, action) => {
//       state.initialName = action.payload;
//     },
//     clearSidebar: (state) => {
//       state.initialName = "";
//     },
//   },
// });

// // Action creators are generated for each case reducer function
// export const { setInitialName, clearSidebar } = sidebarSlice.actions;

// export default sidebarSlice.reducer;
