// ---------------- fetching the group details : 

import React, { useEffect, useState } from "react";
import { useMsal, useAccount } from "@azure/msal-react";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import { InputGroup, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { groupSyncApi, login, azureUserLogin } from '../../service/common.service';
import { decryptText } from '../../utils/index';

const GRAPH_API_BASE_URL = "https://graph.microsoft.com/v1.0";

const Login = () => {
    const { instance, accounts, inProgress } = useMsal();
    const navigate = useNavigate();
    const account = useAccount(accounts[0] || {});
    const [groups, setGroups] = useState<any[]>([]);
    const [groupMembers, setGroupMembers] = useState<any>({});
    const [loaderStatus, setLoaderStatus] = useState(0)
    const [error, setError] = useState('')
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");


    // Function to handle Microsoft login
    const handleMicrosoftLogin = () => {
        instance
            .loginRedirect({
                scopes: ["openid", "profile", "User.Read", "Group.Read.All"],
            })
            .catch((e) => console.error("Login Error: ", e));
    };

    const handleLogin = async () => {
        try {
            const payload = {
                email: userName,
                password: password
            }

            const response: any = await login(payload);

            if (response?.status == 200) {
                const encryptedName = response?.data?.data.name;
                const decryptedName = decryptText(encryptedName);

                // console.log(decryptedName, '[-,decryptedName')
                const dataStore = {
                    email: response?.data?.data.email,
                    isEmailVerified: response?.data?.data.isEmailVerified,
                    name: decryptedName,
                    userId: response?.data?.data.userId,
                    token: response?.data?.data.token
                }

                localStorage.setItem("userData", JSON.stringify(dataStore))

                navigate("/assessments");
                // setUserLoading(0);
            } else if (response?.status == 400) {
                setError(response?.data?.message || 'error');
            } else {
                setError(response?.data?.message || 'error');
            }
        } catch (error) {
            console.error("Login error:", error);
        }
    };

    // Function to fetch group details
    const fetchGroups = async (accessToken: string) => {
        try {
            const groupResponse = await axios.get(
                `${GRAPH_API_BASE_URL}/me/memberOf`, // Fetch user's group memberships
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            const groupsData = groupResponse.data.value.map((group: any) => ({
                id: group.id,
                displayName: group.displayName,
                mail: group.mail,
            }));

            // setGroups(groupsData);
            // localStorage.setItem("groups", JSON.stringify(groupsData)); // Store groups in local storage

            groupSync({ "groups": groupsData })
            // Fetch members for each group
            // groupsData.forEach((group: any) => {
            //     fetchGroupMembers(accessToken, group.id); // Fetch group members
            // });
        } catch (error) {
            console.error("Error fetching groups: ", error);
        }
    };

    // // Function to fetch group members by groupId
    // const fetchGroupMembers = async (accessToken: string, groupId: string) => {
    //     try {
    //         // 8ff87d7d-c552-45f0-a634-19d223eba0b8
    //         // 8ff87d7d-c552-45f0-a634-19d223eba0b8
    //         // 2b4ecc82-8186-4a05-9480-035f6829e35e

    //         const membersResponse = await axios.get(
    //             // `${GRAPH_API_BASE_URL}/groups/${groupId}/members`, // Fetch members of the group
    //             // `${GRAPH_API_BASE_URL}/groups/8ff87d7d-c552-45f0-a634-19d223eba0b8/members`, // Fetch members of the group
    //             `${GRAPH_API_BASE_URL}/groups/2b4ecc82-8186-4a05-9480-035f6829e35e/members`, // Fetch members of the group
    //             {
    //                 headers: {
    //                     Authorization: `Bearer ${accessToken}`,
    //                 },
    //             }
    //         );

    //         localStorage.setItem(`raw_group_response${groupId}`, JSON.stringify(membersResponse)); // Store group members in local storage for each group

    //         const members = membersResponse.data.value.map((member: any) => ({
    //             id: member.id,
    //             displayName: member.displayName,
    //             mail: member.mail,
    //             userPrincipalName: member.userPrincipalName,
    //         }));

    //         setGroupMembers((prevMembers: any) => ({
    //             ...prevMembers,
    //             [groupId]: members, // Store members for the groupId
    //         }));

    //         localStorage.setItem(`groupMembers_${groupId}`, JSON.stringify(members)); // Store group members in local storage for each group
    //     } catch (error) {
    //         console.error(`Error fetching members for group ${groupId}: `, error);
    //     }
    // };

    // // Function for silent login
    // const silentLogin = async () => {
    //     const silentRequest = {
    //         scopes: ["User.Read", "Group.Read.All"], // Include group scope
    //         loginHint: account?.username || "",
    //     };
    //     console.log('flag-3')
    //     try {

    //         //console.log('silentRequest>>',silentRequest)
    //         const loginResponse = await instance.ssoSilent(silentRequest);
    //         //  console.log('loginResponse>>',loginResponse)
    //         const accessToken = loginResponse.accessToken;
    //         localStorage.setItem("accessToken", accessToken); // Store access token in local storage

    //         const decodedToken: any = jwtDecode(loginResponse.idToken);
    //         localStorage.setItem("decodedToken", JSON.stringify(decodedToken)); // Store decoded token

    //         // Fetch groups and members after login
    //         await fetchGroups(accessToken);

    //         navigate("/assessments");
    //     } catch (err) {
    //         if (err) {
    //             //    handleMicrosoftLogin(); // Fallback to interactive login if silent login fails
    //         } else {
    //             console.error("Silent Login Error: ", err);
    //         }
    //     }
    // };



    const groupSync = async (payload: any) => {
        try {
            const response: any = await groupSyncApi(payload);

            if (response?.status == 200) {
                return ''
            } else if (response?.status == 400) {
                setError(response?.data?.message || 'error');
            } else {
                setError(response?.data?.message || 'error');
            }

        } catch (error) {
            console.error("Error insert Departments:", error);
        }
    };

    const azureLoginSync = async (payload: any) => {
        try {

            const response: any = await azureUserLogin(payload);

            if (response?.status == 200) {
                return ''
            } else if (response?.status == 400) {
                setError(response?.data?.message || 'error');
            } else {
                setError(response?.data?.message || 'error');
            }

        } catch (error) {
            console.error("Error insert Departments:", error);
        }
    };


    // const clearSessionAndRedirect = () => {
    //     localStorage.clear(); // Clear all local storage items
    //     handleMicrosoftLogin(); // Redirect to Microsoft login
    // };


    useEffect(() => {
        if (account) {
            instance.handleRedirectPromise().then(async (loginResponse: any) => {
                if (loginResponse) {
                    setLoaderStatus(0)

                    // You can access the access token from the login response
                    // const accessToken = loginResponse.accessToken;
                    const accessToken = loginResponse.accessToken;
                    localStorage.setItem("accessToken", accessToken); // Store access token in local storage

                    const decodedToken: any = jwtDecode(loginResponse.idToken);
                    localStorage.setItem("decodedToken", JSON.stringify(decodedToken)); // Store decoded token

                    // Fetch groups and members after login
                    await fetchGroups(accessToken);

                    navigate("/assessments");

                    // Use the access token for API calls
                }
                else {
                    setLoaderStatus(1);
                    // clearSessionAndRedirect();
                }
            }).catch((error: any) => {
                console.error("Redirect error: ", error);
                setLoaderStatus(1)
                // clearSessionAndRedirect();

            });
        }
        else {
            setLoaderStatus(1)
            // clearSessionAndRedirect();
        }
    }, [account, instance]);

    return (
        <div className="mainWrap">
            <div className="loginPageWrapper">
                <div className="loginPageRight">

                    {
                        loaderStatus == 0 && (
                            <div className="pageLoader">
                                <span className="spinner"></span>
                            </div>
                        )
                    }
                    <div className="loginrightForm">
                        <div className="contentForm">
                            <div className="contentFormHead">
                                <a href="/">
                                    <img className="login-img" src="../../../assets/images/logo-vertical.png" alt="Evvo Logo" />
                                </a>
                                <h4>Login to Wearable Management System</h4>
                            </div>

                            <div className="contentFormBody">
                                <div className="form-group">
                                    <InputGroup>
                                        <InputGroup.Text id="basic-addon1" className="icon-email"></InputGroup.Text>
                                        <Form.Control placeholder="Username" aria-label="Username" aria-describedby="basic-addon1"
                                            value={userName}
                                            onChange={(e) => setUserName(e.target.value)} />
                                    </InputGroup>
                                </div>
                                <div className="form-group">
                                    <InputGroup>
                                        <InputGroup.Text id="basic-addon2" className="icon-password"></InputGroup.Text>
                                        <Form.Control placeholder="******" aria-label="Password" type="password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)} />
                                        <InputGroup.Text id="basic-addon1" className="icon-password-lock cursor-pointer"></InputGroup.Text>
                                    </InputGroup>
                                </div>

                                <div className="forgotLink">
                                    <a href="">Forgot password?</a>
                                </div>
                                {/* 
                                {
                                    !loaderStatus ? */}

                                <div className="submitBtn-wrap">
                                    <Button variant="primary" className="w-100 text-start" onClick={handleLogin} >Login <i className="icon-arrow-right"></i></Button>
                                    <Button variant="outline-secondary" className="w-100 text-start mt-4 logBtnSec" onClick={handleMicrosoftLogin}>
                                        <svg aria-hidden="true" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.5216 0.5H0V11.9067H11.5216V0.5Z" fill="#f25022"></path>
                                            <path d="M24.2418 0.5H12.7202V11.9067H24.2418V0.5Z" fill="#7fba00"></path>
                                            <path d="M11.5216 13.0933H0V24.5H11.5216V13.0933Z" fill="#00a4ef"></path>
                                            <path d="M24.2418 13.0933H12.7202V24.5H24.2418V13.0933Z" fill="#ffb900"></path>
                                        </svg>
                                        Log in with Microsoft
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
