import React, { useEffect, useState } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';

// Define the type for the embed configuration
interface EmbedConfig {
  type: string;
  tokenType: models.TokenType;
  accessToken: string;
  embedUrl: string;
  id: string;
  permissions: models.Permissions;
  settings: {
    filterPaneEnabled: boolean;
    navContentPaneEnabled: boolean;
  };
}

const UserLocations = () => {
  // Use the correct type for the embedConfig state
  const [embedConfig, setEmbedConfig] = useState<EmbedConfig | null>(null);

  useEffect(() => {
    // Set up the embed configuration
    const config: EmbedConfig = {
      type: 'report', // type can be 'report', 'dashboard', 'tile'
      tokenType: models.TokenType.Embed, // Embed token type
      accessToken: 'YOUR_ACCESS_TOKEN', // Replace with your Power BI access token
      embedUrl: 'YOUR_EMBED_URL', // Replace with the embed URL for your Power BI report
      id: 'YOUR_REPORT_ID', // Replace with the Report ID
      permissions: models.Permissions.All, // Permissions (e.g., All, View)
      settings: {
        filterPaneEnabled: true, // Optionally enable or disable the filter pane
        navContentPaneEnabled: true, // Optionally enable or disable the navigation pane
      },
    };

    // Set the configuration in state
    setEmbedConfig(config);
  }, []);

  return (
    <div>
      {embedConfig && (
        <PowerBIEmbed
          embedConfig={embedConfig}
          cssClassName="embed-container"
          getEmbeddedComponent={(embeddedReport) => {
            // You can interact with the embedded report if needed
            console.log(embeddedReport);
          }}
        />
      )}
    </div>
  );
};

export default UserLocations;