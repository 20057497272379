import React from "react";
const Missing = () => {

  return (
    <div>
      <h1>Page Not Found!</h1>
    </div>
  );
};

export default Missing;
