import React from "react";

const AppLoading = () => {
  return (
    <div>
      <div>
        <div />
      </div>
    </div>
  );
};

export default AppLoading;
