import { lazy } from "react";
import { retry } from "../utils/index";
import { HiHome } from "react-icons/hi";

const adminRoutes = [
  // {
  //   name: "login",
  //   initialName: "/login",
  //   path: "/login",
  //   component: lazy((): any => retry(() => import("../pages/officer/login/index"))),
  //   roles: ["ADMIN", "SUPERADMIN"],
  //   menu: true,
  //   icon: HiHome,
  // },
  {
    name: "ForgotPasscode",
    initialName: "/ForgotPasscode",
    path: "/ForgotPasscode",
    component: lazy((): any =>
      retry(() => import("../pages/officer/auth/forgotpasscode/index"))
    ),
    menu: true,
    icon: HiHome,
  },
  // {
  //   name: "register",
  //   initialName: "/register",
  //   path: "/register",
  //   component: lazy((): any =>
  //     retry(() => import("../pages/officer/register/index"))
  //   ),
  //   menu: true,
  //   icon: HiHome,
  // },
  // {
  //   name: "registercoach",
  //   initialName: "/registercoach",
  //   path: "/registercoach",
  //   component: lazy((): any =>
  //     retry(() => import("../pages/officer/register-coach/index"))
  //   ),
  //   menu: true,
  //   icon: HiHome,
  // },
  // {
  //   name: "adminonboard",
  //   initialName: "/adminonboard",
  //   path: "/adminonboard",
  //   component: lazy((): any =>
  //     retry(() => import("../pages/officer/admin-onboarding/index"))
  //   ),
  //   menu: true,
  //   icon: HiHome,
  // },
  // {
  //   name: "coachonboarding",
  //   initialName: "/coachonboarding",
  //   path: "/coachonboarding",
  //   component: lazy((): any =>
  //     retry(() => import("../pages/officer/coach-onboarding/index"))
  //   ),
  //   menu: true,
  //   icon: HiHome,
  // },
  // {
  //   name: "Home",
  //   initialName: "/home",
  //   path: "/home",
  //   component: lazy((): any =>
  //     retry(() => import("../pages/officer/coach-onboarding/index"))
  //   ),
  //   menu: true,
  //   icon: HiHome,
  // },
  // {
  //   name: "coach",
  //   initialName: "/coach",
  //   path: "/coach",
  //   component: lazy((): any =>
  //     retry(() => import("../pages/officer/coach/index"))
  //   ),
  //   menu: true,
  //   icon: HiHome,
  // },
  // {
  //   name: "logincoach",
  //   initialName: "/logincoach",
  //   path: "/logincoach",
  //   component: lazy((): any =>
  //     retry(() => import("../pages/officer/login-coach/index"))
  //   ),
  //   menu: true,
  //   icon: HiHome,
  // },
  // {
  //   name: "adminlogin",
  //   initialName: "/adminlogin",
  //   path: "/adminlogin",
  //   component: lazy((): any =>
  //     retry(() => import("../pages/admin/login/index"))
  //   ),
  //   menu: true,
  //   icon: HiHome,
  // },
  // {
  //   name: "adminregister",
  //   initialName: "/adminregister",
  //   path: "/adminregister",
  //   component: lazy((): any =>
  //     retry(() => import("../pages/admin/register/index"))
  //   ),
  //   menu: true,
  //   icon: HiHome,
  // },
];

export default adminRoutes;
