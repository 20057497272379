import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SideNav, SideNavCollapse, SideNavLink, SideNavButton } from "@govtechsg/sgds-react";
import { useDispatch, useSelector } from "react-redux";
import { setInitialName } from "../../app/features/sidebarSlice";
import { Spinner } from "react-bootstrap"; // Import Spinner component
import { msalInstance } from '../../pages/login/msalConfig';  // Import msalInstance

const SideNavBar: React.FC = () => {
  const userData: any = JSON.parse(localStorage.getItem("decodedToken") || localStorage.getItem("userData") || '{}');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  // Get the current active item from Redux state
  const activeItem = useSelector((state: any) => state.sidebar.initialName);
  const [isTrainingsOpen, setTrainingsOpen] = useState<boolean>(false);

  // Function to handle item click and dispatch the active state to Redux
  const handleItemClick = (itemName: string, toggleDropdown: boolean = false) => {
    dispatch(setInitialName(itemName)); // Set active item in Redux state

    // Open/close dropdown specifically for "Trainings"
    if (itemName === "Trainings") {
      setTrainingsOpen(prevOpen => !prevOpen);
    } else {
      setTrainingsOpen(false); // Ensure dropdown closes for other items
    }
  };

  const handleLogout = async () => {
    try {
      // console.log('Logging out...');

      // Optional: Clear localStorage and sessionStorage if necessary
      localStorage.clear();
      sessionStorage.clear();

      // Optional: Dispatch Redux action to update the user state (if using Redux)
      dispatch({ type: 'USER_LOGOUT' }); // Replace with your Redux action for logout

      // Perform MSAL logout
      await msalInstance.logoutRedirect(); // Silent logout using redirect

      // After logout, navigate to the login page (or wherever you want)
      navigate('/auth');
    } catch (error) {
      console.error('Logout error: ', error);
      // Optional: Handle error and navigate to a different page in case of an error
      navigate('/auth');
    }
  };



  // Automatically open the Trainings dropdown if the active item is related to "Trainings"
  useEffect(() => {
    if (activeItem === "Manage Trainings" || activeItem === "Live Training & Tracking" || activeItem === "CompletedTraining") {
      setTrainingsOpen(true); // Open the dropdown when accessing sub-items
    } else if (activeItem !== "Trainings") {
      setTrainingsOpen(false); // Close dropdown when a non-related item is active
    }
  }, [activeItem]);


  return (
    <>

      <nav id="sidebar">
        <div className="sideBarSticky">
          <div className="sidebar-header d-flex align-items-center">
            <div className="title">
              <h1 className="h5">Welcome to SCDF!</h1>
              <p className="adminName">{userData.name}</p>
            </div>
          </div>
          <SideNav className="list-unstyled">
            <SideNav.Item
              eventKey="Dashboard"
              className={activeItem === "Dashboard" ? "active" : ""}
              onClick={() => handleItemClick("Dashboard")}
            >
              <Link to="/user-management">Dashboard</Link>
            </SideNav.Item>

            <SideNav.Item
              eventKey="User Management"
              className={activeItem === "User Management" ? "active" : ""}
              onClick={() => handleItemClick("User Management")}
            >
              <Link to="/user-management">User Management</Link>
            </SideNav.Item>

            {/* Trainings Dropdown */}
            <SideNav.Item
              eventKey="Trainings"
              className={
                activeItem === "Trainings" ||
                  activeItem === "Manage Trainings" ||
                  activeItem === "Live Training & Tracking" || 
                  activeItem === "CompletedTraining"
                  ? activeItem === "Trainings"
                    ? "active"
                    : "selected active"
                  : ""
              }
            >
              <SideNavButton onClick={() => handleItemClick("Trainings", true)}>
                Trainings
              </SideNavButton>
              <SideNavCollapse in={isTrainingsOpen}>
                <>
                  <SideNavLink
                    eventKey="Manage Trainings"
                    className={activeItem === "Manage Trainings" ? "active" : ""}
                    as={Link}
                    to="/manage-trainings-events"
                    onClick={() => handleItemClick("Manage Trainings")}
                  >
                    Manage Trainings
                  </SideNavLink>
                  <SideNavLink
                    eventKey="Live Training & Tracking"
                    className={activeItem === "Live Training & Tracking" ? "active" : ""}
                    as={Link}
                    to="/live-training-and-tracking"
                    onClick={() => handleItemClick("Live Training & Tracking")}
                  >
                    Live Training & Tracking
                  </SideNavLink>
                  <SideNavLink
                    eventKey="CompletedTraining"
                    className={activeItem === "CompletedTraining" ? "active" : ""}
                    as={Link}
                    to="/completed-training-and-tracking"
                    onClick={() => handleItemClick("CompletedTraining")}
                  >
                    Completed Training
                  </SideNavLink>
                </>
              </SideNavCollapse>
            </SideNav.Item>

            <SideNav.Item
              eventKey="Assessments"
              className={activeItem === "Assessments" ? "active" : ""}
              onClick={() => handleItemClick("Assessments")}
            >
              <Link to="/assessments">Assessments</Link>
            </SideNav.Item>

            {/* <SideNav.Item
            eventKey="Logout"
            className={activeItem === "Logout" ? "active" : ""}
            onClick={handleLogout} // Call handleLogout on logout click
          >
            <Link to="#">
              <i className="icon-logout md-20"></i> Logout
            </Link>
          </SideNav.Item> */}
            <SideNav.Item
              eventKey="Logout"
              className={activeItem === "Logout" ? "active" : ""}
              onClick={handleLogout}
            >
              <Link to="#">
                {loading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  <>
                    <i className="icon-logout md-20"></i> Logout
                  </>
                )}
              </Link>
            </SideNav.Item>

          </SideNav>
        </div>
      </nav>

    </>
  );
};

export default SideNavBar;