import React from "react";
import { Dropdown } from "react-bootstrap";



const TopNav = () => {

  const handleToggleClass = () => {
    document.body.classList.toggle('sideMenuFixed');
  };

  return (
    <header className="header topHeader">
      <nav className="navbar navbar-expand-lg">
        <div className="d-flex align-items-center justify-content-between w-100">
          <div className="navbar-header">
            <a className="navbar-brand" href="/"><img className="logo_img" src="../../assets/images/logo.png" alt="Evvo Logo" /></a>
            <span className="navbar-brand-cont">WMS</span>
            <button onClick={handleToggleClass} className="sidebar-toggle"><span className="toggle-icon"></span></button>
          </div>

            

          <div className="right-menu d-flex no-margin-bottom">

          <Dropdown className="d-none d-lg-flex">
            <Dropdown.Toggle className="nav-link messages-toggle" id="dropdown-basic">
                <i className="icon-envelope"></i>
                <span  className="badge dashbg-1"></span>
            </Dropdown.Toggle>

            <Dropdown.Menu className="messages">
              <Dropdown.Item href="#/action-1">Dark</Dropdown.Item>
              <Dropdown.Item href="#/action-1">Light</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown className="d-none d-lg-flex">
            <Dropdown.Toggle className="nav-link messages-toggle arrowHide" id="dropdown-basic">
                <i  className="icon-notification"></i>
                <span  className="badge dashbg-1"></span>
            </Dropdown.Toggle>

            <Dropdown.Menu className="messages">
              <Dropdown.Item className="text-center message d-block" href="#/action-1">See All Messages  <i className="fa fa-angle-right ms-1" aria-hidden="true"></i></Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown className="">
            <Dropdown.Toggle className="nav-link messages-toggle arrowHide" id="dropdown-basic">
                <span className="profileImg"><img className="logo_img" src="../../assets/images/user.png" alt="user thumb" /></span>
            </Dropdown.Toggle>

            <Dropdown.Menu className="messages">
              <Dropdown.Item className="text-center message d-block" href="#/action-1">See All Messages  <i className="fa fa-angle-right ms-1" aria-hidden="true"></i></Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

            
          </div>
          
        </div>
      </nav>
    </header>
  );
};

export default TopNav;
