import React from "react";
import { Dropdown } from "react-bootstrap";


const Footer = () => {
  return (
    <div className="footText">
      © SCDF 2024
    </div>
  );
};

export default Footer;
