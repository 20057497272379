import axiosInstance from "./axios.service";
import { getLocalStorage } from "../utils";
import { azureLogin, generateInviteCode, syncAzureUserApi, fetchUsersDetails, groupSync, Training, createTraining, fetchDepartments, fetchTrainingListByDateRange, assessmentUrl, assessmentQuestnUrl } from "./config";

const initialData = getLocalStorage("initialData");
const token = initialData?.intialData?.token || "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjoiVTJGc2RHVmtYMTlJR0ZTTlZtMmkzN2VwdnBETG5VYmJNQjByQkgwYUlyY2gvZHdTWGp4cjA0QXg0M2pkRFMvVmZZSktkMk9jMzV6aWdPSnNWd1h1QWVnM0NyN3dBYS93L21NaStOR2NIclpTZTFQZ2QyZ3FnUjN3L0JxWnhYM2RwWHIraytMUGFKODg2RXRaOTRHVE9hd2JjV0N1TUtsTzhwYk0rQ3RKM05DYWpuSzdXdmgrNEN4STJpa0NjZzF2b0JaR2VJZUp3Z3hyTldYZ1R0a0VxVVM1cWlnMXhqVER6NW5kQmJiN0FFOD0iLCJpYXQiOjE3MjUzMzE1MjMsImV4cCI6MTcyNTQxNzkyM30.DUEyN4h8CGci6Uag8G76SAG7fHXB04ikVF9-4OZkBwA";

const headersToken = '$2b$10$MRFgX9g6FxSPQMHz2AefWeUMAnGfCuLUvEtBHEptGMdZQYlqyfQD.'

const apiRequest = async (
    url: string,
    method: "get" | "post" | "put",
    data?: any,
    requestToken?: string
) => {
    try {
        const authToken = requestToken || token;

        if (!authToken && !url.includes(`${fetchUsersDetails}/login`)) {
            console.error('No authentication token found.');
            return null;
        }

        //console.log('Retrieved Token:', authToken);

        // Default headers, set Authorization for requests other than login
        let headers = url.includes(`${fetchUsersDetails}/login`)
            ? { token: headersToken }
            : { Authorization: `Bearer ${authToken}` };

        const res = await axiosInstance({
            url,
            method,
            data,
            headers,
        });

        if (res.status === 200 || res.status === 201) {
            return res;
        }

        console.error('Unexpected response status:', res.status);
        return null;
    } catch (error: any) {
        console.error('API Request Error:', error.response);
        // throw error.response;
        return error.response
    }
};



// const apiRequest = async (
//     url: string,
//     method: "get" | "post" | "put",
//     data?: any,
//     requestToken?: string
// ) => {
//     try {
//         const authToken = requestToken || token;

//         if (!authToken) {
//             console.error('No authentication token found.');
//             return null;
//         }

//         console.log('Retrieved Token:', authToken);

//         let headers = {
//             Authorization: `Bearer ${authToken}`,
//         };

//         if (url.includes(`${fetchUsersDetails}/login`)) {
//             console.log(url, '-<,url..-> inside')
//             // headers = {
//             //     token: `${headersToken}`,
//             // };
//             headers.token = `${headersToken}`
//         }

//         const res = await axiosInstance({
//             url,
//             method,
//             data,
//             headers,
//         });

//         if (res.status === 200 || res.status === 201) {
//             return res;
//         }

//         console.error('Unexpected response status:', res.status);
//         return null;
//     } catch (error: any) {
//         console.error('API Request Error:', error);
//         throw error.response;
//     }
// };


export const azureUserLogin = async (data: any) => {
    return await apiRequest(`${azureLogin}/azure-login`, "post", data);
};


export const createTrainingApi = async (data: any) => {
    return await apiRequest(`${createTraining}/create-training`, "post", data);
};


export const fetchDepartment = async () => {
    return await apiRequest(`${fetchDepartments}/fetch-department`, "get");
};

export const requestNewInviteCode = async (data: any) => {
    return await apiRequest(`${generateInviteCode}/request-new-invite-code`, "post", data);
};



export const fetchTrainingsListByDateRange = async (data: any) => {
    return await apiRequest(`${fetchTrainingListByDateRange}/list-by-date-range`, "post", data);
};


export const groupSyncApi = async (data: any) => {
    return await apiRequest(`${groupSync}`, "post", data);
};


export const updateTraining = async (data: any) => {
    return await apiRequest(`${Training}/update`, "post", data);
};


export const cancelTrainingApi = async (data: any) => {
    return await apiRequest(`${Training}/cancel`, "post", data);
};


/*** users */
export const fetchUsersDetailApi = async (data: any) => {
    return await apiRequest(`${fetchUsersDetails}/fetch-user-details`, "post", data);
};
export const login = async (data: any) => {
    return await apiRequest(`${fetchUsersDetails}/login`, "post", data);
};



export const syncAzureData = async (data: any) => {
    return await apiRequest(`${syncAzureUserApi}/sync-azure-data`, "post", data);
};


export const getFormattedCurrentTime = () => {
    // const today = new Date();
    const currentTime = new Date();
    const hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();
    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

    return formattedTime
}

export const addRecord = async (data: any, path: string = '', method: any = 'post') => {
    return await apiRequest(`${assessmentUrl}/create-record`, method, data);
};

export const getTrainingListByDpId = async (data: any,path:string='',method:any='post') => {
    return await apiRequest(`${Training}/get-training-list-by-dpId`, method, data);
};

export const getHeartRateZoneList = async (data: any,path:string='',method:any='post') => {
    return await apiRequest(`${Training}/get-heart-rate-zone-list`, method, data);
};

export const fetchAssessments = async (data: any) => {
    return await apiRequest(`${assessmentUrl}/get-assessmnt-list`, "post", data);
};

export const deleteAssessment = async (data: any) => {
    return await apiRequest(`${assessmentUrl}/delete-record`, "post", data);
};

export const getAssessmentRecord = async (data: any) => {
    return await apiRequest(`${assessmentUrl}/get-record`, "post", data);
};
export const updateAssessmentRecord = async (data: any, path: string = '', method: any = 'post') => {
    return await apiRequest(`${assessmentUrl}/update-record`, method, data);
};

//questionnaire

export const addquestionnaire = async (data: any, path: string = '', method: any = 'post') => {
    return await apiRequest(`${assessmentQuestnUrl}/create-record`, method, data);
};

export const fetchquestionnaire = async (data: any) => {
    return await apiRequest(`${assessmentQuestnUrl}/get-record-list-by-aId`, "post", data);
};

export const deleteQuestionnaire = async (data: any) => {
    return await apiRequest(`${assessmentQuestnUrl}/delete-record`, "post", data);
};