import { Card, Col, Row } from "@govtechsg/sgds-react";
import { useState } from 'react';
import SideNavBar from "../../components/Sidebar-left/sideBar";
import TopNav from "../../components/top-nav";
import Footer from "../../components/footer";
import { Dropdown, Form, Modal, Button, Badge } from "react-bootstrap";
import Select from 'react-select';


const options = [
  { value: 'Dashboard', label: 'Dashboard' },
  { value: 'Monitoring & Alerts', label: 'Monitoring & Alerts' },
  { value: 'Health Data', label: 'Health Data' }
]

const Roles = () => {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  

  return (
    <>
      <TopNav />
      <div className="d-flex align-items-stretch">
        <SideNavBar />
        <div className="page-content pb-4">
          <div className="bredcrumb">
            <ul>
              <li><a href="#">Home</a></li>
              <li>Roles</li>
            </ul>
          </div>
          <section className="blockWrap no-padding-bottom p-0">
            <div className="card">
              <div className="card-header d-flex align-items-center justify-content-between">
                  <div className="cardTitle mx-w-580">
                    <h5>Manage Roles</h5>
                    <p>A role provided access to predefined menus and features so that depending on
assigned role an administrator can have access to what user needs.</p>
                  </div>
                  <a href="create-roles"  className="btn btn-primary mn-w-148">Map Role</a>
              </div>

              <div className="card-body">
                  <div className="filterBar">
                    <div className="title fw-700 fs-14">Total Roles [ 4 ]</div>
                  </div>

                  <div className="assessmentsBlockWrap">
                    <div className="assessmentsBlock">
                      <div className="assessmentsBlockLeft">
                        <div className="profilBlock">
                          <div className="img"><i className="icon-user"></i></div>
                          <div className="cont">
                            <h5>Commander</h5>
                            <p>Mange the Health data and User Management</p>
                          </div>
                        </div>
                      </div>
                      <div className="assessmentsBlockRight justify-content-lg-end align-items-center">

                        <div className="assignBlock">
                          <i className="icon-user"></i>
                          <span className="cont">Assigned Users</span>
                          <span className="value">12</span>
                        </div>
                        <Badge bg="success" className="mn-w-96">Active</Badge>
                        <div className="actionWrap">
                          <div className="action">
                            <Button variant="outline-primary" onClick={handleShow}><i className="icon-edit"></i></Button>
                            <Button variant="outline-primary"><i className="icon-delete"></i></Button>
                          </div>
                          <Dropdown>
                            <Dropdown.Toggle id="dropdown-basic">
                              <i className="icon-dots-v"></i>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                              <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                              <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>


                    <div className="assessmentsBlock">
                      <div className="assessmentsBlockLeft">
                        <div className="profilBlock">
                          <div className="img"><i className="icon-user"></i></div>
                          <div className="cont">
                            <h5>Training Coordinator</h5>
                            <p>Mange the Trainings and Assign trainings</p>
                          </div>
                        </div>
                      </div>
                      <div className="assessmentsBlockRight justify-content-lg-end align-items-center">

                        <div className="assignBlock">
                          <i className="icon-user"></i>
                          <span className="cont">Assigned Users</span>
                          <span className="value">03</span>
                        </div>
                        <Badge bg="success" className="mn-w-96">Active</Badge>
                        <div className="actionWrap">
                          <div className="action">
                            <Button variant="outline-primary" onClick={handleShow}><i className="icon-edit"></i></Button>
                            <Button variant="outline-primary"><i className="icon-delete"></i></Button>
                          </div>
                          <Dropdown>
                            <Dropdown.Toggle id="dropdown-basic">
                              <i className="icon-dots-v"></i>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                              <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                              <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>


                    <div className="assessmentsBlock">
                      <div className="assessmentsBlockLeft">
                        <div className="profilBlock">
                          <div className="img"><i className="icon-user"></i></div>
                          <div className="cont">
                            <h5>Medical Practitioners</h5>
                            <p>Mange the health data and actions accordingly</p>
                          </div>
                        </div>
                      </div>
                      <div className="assessmentsBlockRight justify-content-lg-end align-items-center">

                        <div className="assignBlock">
                          <i className="icon-user"></i>
                          <span className="cont">Assigned Users</span>
                          <span className="value">12</span>
                        </div>
                        <Badge bg="success" className="mn-w-96">Active</Badge>
                        <div className="actionWrap">
                          <div className="action">
                            <Button variant="outline-primary" onClick={handleShow}><i className="icon-edit"></i></Button>
                            <Button variant="outline-primary"><i className="icon-delete"></i></Button>
                          </div>
                          <Dropdown>
                            <Dropdown.Toggle id="dropdown-basic">
                              <i className="icon-dots-v"></i>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                              <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                              <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>

                  </div>
              </div>
              
              

              
            </div>


          </section>

          <Footer />
        </div>
      </div>

      <Modal className="mw-1080" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Role</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
              <Row>
                <Col lg="6">
                  <div className="form-group d-light">
                    <label>Role Title</label>
                    <input type="text" value="Sub Admin" placeholder="Enter Role Title" />
                  </div>
                </Col>

                <Col lg="6">
                  <div className="form-group d-light">
                    <label>Training Description</label>
                    <input type="text" value="Mange the Health data, Trainings, Events and User Management" placeholder="Enter Role Description" />
                  </div>
                </Col>
              </Row>


              <div className="componentBlock">
                    <div className="componentHeader">
                      <div className="title">
                        <h5>Module Access</h5>
                      </div>
                      <div className="action">
                        <Select className="selectpicker" placeholder="Select role type" options={options} />
                      </div>
                    </div>
                    <div className="componentBody">
                      <Row>
                        <Col lg="4">
                          <div className="roleNameBlock">
                            <div className="roleNameBlockhead">
                                <h6>Dashboard</h6>
                                <Form.Check  type="switch" id="custom-switch" label="Off" />
                            </div>
                            <div className="roleNameBlockBody">
                              <div className="inline-fields-wrap">
                                  <div className="custom-check-field">
                                    <input type="checkbox" name="Dashboard" value="Create" />
                                    <label>Create</label>
                                  </div>

                                  <div className="custom-check-field">
                                    <input type="checkbox" name="Dashboard" value="View" />
                                    <label>View</label>
                                  </div>

                                  <div className="custom-check-field">
                                    <input type="checkbox" name="Dashboard" value="Edit" />
                                    <label>Edit</label>
                                  </div>

                                  <div className="custom-check-field">
                                    <input type="checkbox" name="Dashboard" value="Delete" />
                                    <label>Delete</label>
                                  </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col lg="4">
                          <div className="roleNameBlock">
                            <div className="roleNameBlockhead">
                                <h6>Monitoring & Alerts</h6>
                                <Form.Check  type="switch" id="alerts-switch" label="Off" />
                            </div>
                            <div className="roleNameBlockBody">
                              <div className="inline-fields-wrap">
                                  <div className="custom-check-field">
                                    <input type="checkbox" name="Dashboard" value="Create" />
                                    <label>Create</label>
                                  </div>

                                  <div className="custom-check-field">
                                    <input type="checkbox" name="Dashboard" value="View" />
                                    <label>View</label>
                                  </div>

                                  <div className="custom-check-field">
                                    <input type="checkbox" name="Dashboard" value="Edit" />
                                    <label>Edit</label>
                                  </div>

                                  <div className="custom-check-field">
                                    <input type="checkbox" name="Dashboard" value="Delete" />
                                    <label>Delete</label>
                                  </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col lg="4">
                          <div className="roleNameBlock">
                            <div className="roleNameBlockhead">
                                <h6>Health Data</h6>
                                <Form.Check  type="switch" id="health-data-switch" label="Off" />
                            </div>
                            <div className="roleNameBlockBody">
                              <div className="inline-fields-wrap">
                                  <div className="custom-check-field">
                                    <input type="checkbox" name="Dashboard" value="Create" />
                                    <label>Create</label>
                                  </div>

                                  <div className="custom-check-field">
                                    <input type="checkbox" name="Dashboard" value="View" />
                                    <label>View</label>
                                  </div>

                                  <div className="custom-check-field">
                                    <input type="checkbox" name="Dashboard" value="Edit" />
                                    <label>Edit</label>
                                  </div>

                                  <div className="custom-check-field">
                                    <input type="checkbox" name="Dashboard" value="Delete" />
                                    <label>Delete</label>
                                  </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col lg="4">
                          <div className="roleNameBlock">
                            <div className="roleNameBlockhead">
                                <h6>Training & Events</h6>
                                <Form.Check  type="switch" id="training-events-switch" label="Off" />
                            </div>
                            <div className="roleNameBlockBody">
                              <div className="inline-fields-wrap">
                                  <div className="custom-check-field">
                                    <input type="checkbox" name="Dashboard" value="Create" />
                                    <label>Create</label>
                                  </div>

                                  <div className="custom-check-field">
                                    <input type="checkbox" name="Dashboard" value="View" />
                                    <label>View</label>
                                  </div>

                                  <div className="custom-check-field">
                                    <input type="checkbox" name="Dashboard" value="Edit" />
                                    <label>Edit</label>
                                  </div>

                                  <div className="custom-check-field">
                                    <input type="checkbox" name="Dashboard" value="Delete" />
                                    <label>Delete</label>
                                  </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col lg="4">
                          <div className="roleNameBlock">
                            <div className="roleNameBlockhead">
                                <h6>User Managements</h6>
                                <Form.Check  type="switch" id="user-managements-switch" label="Off" />
                            </div>
                            <div className="roleNameBlockBody">
                              <div className="inline-fields-wrap">
                                  <div className="custom-check-field">
                                    <input type="checkbox" name="Dashboard" value="Create" />
                                    <label>Create</label>
                                  </div>

                                  <div className="custom-check-field">
                                    <input type="checkbox" name="Dashboard" value="View" />
                                    <label>View</label>
                                  </div>

                                  <div className="custom-check-field">
                                    <input type="checkbox" name="Dashboard" value="Edit" />
                                    <label>Edit</label>
                                  </div>

                                  <div className="custom-check-field">
                                    <input type="checkbox" name="Dashboard" value="Delete" />
                                    <label>Delete</label>
                                  </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col lg="4">
                          <div className="roleNameBlock">
                            <div className="roleNameBlockhead">
                                <h6>Assessments</h6>
                                <Form.Check  type="switch" id="assessments-switch" label="Off" />
                            </div>
                            <div className="roleNameBlockBody">
                              <div className="inline-fields-wrap">
                                  <div className="custom-check-field">
                                    <input type="checkbox" name="Dashboard" value="Create" />
                                    <label>Create</label>
                                  </div>

                                  <div className="custom-check-field">
                                    <input type="checkbox" name="Dashboard" value="View" />
                                    <label>View</label>
                                  </div>

                                  <div className="custom-check-field">
                                    <input type="checkbox" name="Dashboard" value="Edit" />
                                    <label>Edit</label>
                                  </div>

                                  <div className="custom-check-field">
                                    <input type="checkbox" name="Dashboard" value="Delete" />
                                    <label>Delete</label>
                                  </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
          
          
          
        </Modal.Body>
        <Modal.Footer className="bt-0 pt-3 pb-4">
          <Button variant="primary" className="m-0" onClick={handleClose}>Save Chnages</Button>
          
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Roles;
