import { Card, Col, Row } from "@govtechsg/sgds-react";
import React, { useEffect, useRef, useState } from 'react';
import SideNavBar from "../../components/Sidebar-left/sideBar";
import { Scrollbar } from 'react-scrollbars-custom'
import TopNav from "../../components/top-nav";
import Footer from "../../components/footer";
import { Container, Dropdown, Pagination, Modal, Button, Form } from "react-bootstrap";
import ReactFlatpickr from 'react-flatpickr';
import Select from 'react-select';
import 'flatpickr/dist/flatpickr.css';
import CalendarComponent from "../../components/calendar";
import MyDatePicker from "../../components/datepicker";
import { createTrainingApi, fetchDepartment, fetchTrainingsListByDateRange, getFormattedCurrentTime } from '../../service/common.service';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import { setInitialName } from "../../app/features/sidebarSlice"; // Import the action from sideNavSlice
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { isTokenValid } from "../../utils/TokenValid";


const options = [
  { value: 'Outdoor Training', label: 'Outdoor Training' },
  { value: 'Indoor Training', label: 'Indoor Training' }
];

const optionsArea = [
  { value: 'Ground 1', label: 'Ground 1' },
  { value: 'Ground 2', label: 'Ground 2' }
];

interface FormData {
  type: string;
  trainingProgram?: string;
  trainingTitle?: string;
  trainingDescription?: string;
  department?: { value: string; label: string };
  area?: string;
  startDate?: Date | null;
  endDate?: Date | null;
  startTime?: string;
  endTime?: string;
}

interface FormErrors {
  type?: string;
  trainingProgram?: string;
  trainingTitle?: string;
  trainingDescription?: string;
  department?: string;
  area?: string;
  startDate?: string;
  endDate?: string;
  startTime?: string;
  endTime?: string;
}


const ManageTrainingEvents = () => {
  const [formData, setFormData] = useState<FormData>({
    type: "training",
    trainingProgram: '',
    trainingTitle: '',
    trainingDescription: '',
    department: undefined,
    area: '',
    startDate: null,
    endDate: null,
    startTime: '',
    endTime: ''
  });

  const [formErrors, setFormErrors] = useState<FormErrors>({});

  const [date, setDate] = useState(new Date());
  const [calenderDate, setCalenderDate] = useState(null as any);

  const [showFirstModal, setShowFirstModal] = useState(false);
  const [showSecondModal, setShowSecondModal] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [error, setError] = useState('')
  const [trainingList, setTrainingList] = useState([]);
  const today = new Date();
  const [formattedTime, setFormattedTime] = useState('');
  const [refreshPage, setRefreshPage] = useState(false);
  const [apiError, setApiError] = useState('');
  const [selectedDepartments, setSelectedDepartments] = useState<any[]>([]);
  const childRef: any = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const activeItem = useSelector((state: any) => state.sidebar.initialName);
  const navigate = useNavigate();


  // console.log(activeItem, '-<<activeItem')

  const handleOpenFirstModal = () => {
    setFormErrors({})
    setShowFirstModal(true);
    setApiError('')
  }


  const handleCloseFirstModal = () => {
    setShowFirstModal(false);
    setFormErrors({})
    setError('')

    setFormData({
      type: "training",
      trainingProgram: '',
      trainingTitle: '',
      trainingDescription: '',
      department: undefined,
      area: '',
      startDate: null,
      endDate: null,
      startTime: '',
      endTime: ''
    });
  };


  const handleOpenSecondModal = () => {
    setShowFirstModal(false);
    setShowSecondModal(true);
  };
  const handleCloseSecondModal = () => {
    setFormData({
      type: "training",
      trainingProgram: '',
      trainingTitle: '',
      trainingDescription: '',
      department: undefined,
      area: '',
      startDate: null,
      endDate: null,
      startTime: '',
      endTime: ''
    });

    setShowSecondModal(false);
    setRefreshPage(refreshPage => !refreshPage)

  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    // Clear endTime when startTime is modified
    if (name === 'startTime') {
      setFormData({ ...formData, startTime: value, endTime: '' });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };



  const handleSelectChange = (name: any, selectedOption: any) => {
    setFormData({ ...formData, [name]: selectedOption });
  };

  const handleDepartmentClick = (department: any, isChecked: boolean) => {
    setSelectedDepartments((prevSelected: any) => {
      if (isChecked) {
        // Add the department if checked and not already in the array
        return [...prevSelected, department];
      } else {
        // Remove the department if unchecked
        return prevSelected.filter((dept: any) => dept.id !== department.id);
      }
    });
  };

  const optionsDepartment = departments.map((el: any) => ({
    value: el.id,
    label: el.departmentName,
  }));


  const validateForm = (
    formData: {
      type: string;
      trainingProgram: string;
      trainingTitle: string;
      trainingDescription: string;
      department: any;
      area: string;
      startDate: string | null;
      endDate: string | null;
      startTime: string;
      endTime: string;
    },
    setFormErrors: any
  ) => {
    let errors: Record<string, string> = {};

    // Check for empty fields and add error messages
    if (!formData.type) {
      errors.type = 'Training Type is required';
    }
    if (!formData.trainingProgram || formData.trainingProgram.trim() === '') {
      errors.trainingProgram = 'Training Program is required';
    }
    if (!formData.trainingTitle || formData.trainingTitle.trim() === '') {
      errors.trainingTitle = 'Training Title is required';
    } else if (formData.trainingTitle.length <= 3) {
      errors.trainingTitle = 'Training Title must be more than 3 characters';
    }
    if (!formData.trainingDescription || formData.trainingDescription.trim() === '') {
      errors.trainingDescription = 'Training Description is required';
    } else if (formData.trainingDescription.length <= 3) {
      errors.trainingDescription = 'Training Description must be more than 3 characters';
    } else if (formData.trainingDescription.length > 256) {
      errors.trainingDescription = 'Training Description cannot exceed 256 characters';
    }

    if (!formData.department) {
      errors.department = 'Department is required';
    }


    if (!formData.area || formData.area.trim() === '') {
      errors.area = 'Area & Location is required';
    }


    if (!formData.startDate) {
      errors.startDate = 'Start Date is required';
    } else {

      console.log(formData.startDate, '-<<formData.startDate');

      // today.setHours(0, 0, 0, 0); // Set to midnight
      const startDate = new Date(formData.startDate);
    }

    // Validate End Date
    if (!formData.endDate) {
      errors.endDate = 'End Date is required';
    } else {
      today.setHours(0, 0, 0, 0); // Reset time to the start of the day
      const endDate = new Date(formData.endDate);
      if (formData.startDate && new Date(formData.startDate) > endDate) {
        errors.endDate = 'End Date must be after the Start Date';
      }
    }

    if (!formData.startTime || formData.startTime.trim() === '') {
      errors.startTime = 'Start Time is required';
    }
    if (!formData.endTime || formData.endTime.trim() === '') {
      errors.endTime = 'End Time is required';
    }

    // Validate Start Time
    if (formData.startTime) {

      if (formData.startDate && new Date(formData.startDate) > new Date()) {
        //  console.log('start date is greater')
      }
      else {
        // console.log('start date is lesser')
        if (formData.startTime < formattedTime) {
          errors.startTime = 'Start time cannot be in the past';
        }

      }
    }



    // Validate End Time
    if (formData.endTime) {
      const startDate = new Date(formData.startDate!);
      const endTime = new Date(`${startDate.toISOString().split('T')[0]}T${formData.endTime}:00`);
      const startTime = new Date(`${startDate.toISOString().split('T')[0]}T${formData.startTime}:00`);

      if (formData.endDate && new Date(formData.endDate) > new Date()) {
        if (endTime <= startTime) {
          errors.endTime = 'End time must be after the Start time2';
        }
      }
      else {
        if (formData.endTime < formattedTime) {
          errors.endTime = 'End time cannot be in the past';
        }
        else if (formData.startTime) {
          if (endTime < startTime) {
            errors.endTime = 'End time must be after the Start time';
          }
        }
      }

    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async () => {
    setFormErrors({})
    const processedFormData = {
      ...formData,
      trainingProgram: formData.trainingProgram ?? '',
      trainingTitle: formData.trainingTitle ?? '',
      trainingDescription: formData.trainingDescription ?? '',
      department: formData.department?.value ?? '',
      // department: typeof formData.department === 'string' ? formData.department : formData.department?.name ?? '',
      area: formData.area ?? '',
      // startDate: formData.startDate ? formData.startDate.toISOString().slice(0, 10) : null, // Convert Date to string
      startDate: formData.startDate ? new Date(formData.startDate).toString() : null, // Convert Date to string
      // endDate: formData.endDate ? formData.endDate.toISOString().slice(0, 10) : null, // Convert Date to string
      endDate: formData.endDate ? new Date(formData.endDate).toString() : null, // Convert Date to string
      startTime: formData.startTime ?? '',
      endTime: formData.endTime ?? '',
      type: formData.type ?? ''
    };

    // Validate the form before submitting
    if (!validateForm(processedFormData, setFormErrors)) return;


    // const payload = {
    //   trainingType: formData.type || '',
    //   trainingProgram: processedFormData.trainingProgram,
    //   trainingTitle: processedFormData.trainingTitle,
    //   trainingDescription: processedFormData.trainingDescription,
    //   departmentId: processedFormData.department,
    //   area_location: processedFormData.area,
    //   startDate: moment(processedFormData.startDate).format('YYYY-MM-DD'),
    //   endDate: moment(processedFormData.endDate).format('YYYY-MM-DD'),
    //   startTime: processedFormData.startTime,
    //   endTime: processedFormData.endTime
    // };


    console.log(processedFormData.startDate, '-<<processedFormData.startDate', processedFormData.endDate, '-<<processedFormData.endDate', processedFormData.startTime, '-<<processedFormData.startTime', processedFormData.endTime, '-<processedFormData.endTime')
    const payload = {
      trainingType: formData.type || '',
      trainingProgram: processedFormData.trainingProgram,
      trainingTitle: processedFormData.trainingTitle,
      trainingDescription: processedFormData.trainingDescription,
      departmentId: processedFormData.department,
      area_location: processedFormData.area,

      // Local time and date
      startDate: momentTZ(processedFormData.startDate).format('YYYY-MM-DD'),
      endDate: momentTZ(processedFormData.endDate).format('YYYY-MM-DD'),
      startTime: processedFormData.startTime,
      endTime: processedFormData.endTime,

      // UTC time and date conversions with date consistency
      startDate_utc: momentTZ.tz(
        `${momentTZ(processedFormData.startDate).format('YYYY-MM-DD')} ${processedFormData.startTime || '00:00'}`,
        'YYYY-MM-DD HH:mm',
        momentTZ.tz.guess()
      ).utc().format('YYYY-MM-DD'),

      endDate_utc: momentTZ.tz(
        `${momentTZ(processedFormData.endDate).format('YYYY-MM-DD')} ${processedFormData.endTime || '23:59'}`,
        'YYYY-MM-DD HH:mm',
        momentTZ.tz.guess()
      ).utc().format('YYYY-MM-DD'),

      startTime_utc: momentTZ.tz(
        `${momentTZ(processedFormData.startDate).format('YYYY-MM-DD')} ${processedFormData.startTime || '00:00'}`,
        'YYYY-MM-DD HH:mm',
        momentTZ.tz.guess()
      ).utc().format('HH:mm'),

      endTime_utc: momentTZ.tz(
        `${momentTZ(processedFormData.endDate).format('YYYY-MM-DD')} ${processedFormData.endTime || '23:59'}`,
        'YYYY-MM-DD HH:mm',
        momentTZ.tz.guess()
      ).utc().format('HH:mm'),

      // UTC timestamp for current time
      utc_timestamp: momentTZ().utc().format(),
      timeZone: momentTZ.tz.guess()  // This will get the client's local timezone
    };

    console.log(payload, '-<,payload');

    try {


      const response: any = await createTrainingApi(payload);

      // console.log(response, '-<<response');

      if (response?.status == 200) {
        setFormErrors({})
        setShowFirstModal(false);
        setShowSecondModal(true);
        setError('')
        setRefreshPage(refreshPage => !refreshPage);
      } else if (response?.status == 400) {
        setError(response?.data?.data || 'error');
      } else if (response?.status == 500) {
        setError('Internal server error');
      } else {
        setError('Internal server error');
      }

    } catch (error: any) {
      // Handle error
      console.error("Error creating training:", error.data.data[0]);
      setApiError(error?.data?.data[0] || '')
    }
  };

  const fetchDepartmentsList = async () => {
    try {
      const response: any = await fetchDepartment();

      if (response?.status == 200) {
        setDepartments(response?.data?.data)

      } else if (response?.status == 400) {
        setError(response?.data?.message || 'error');
      } else {
        setError(response?.data?.message || 'error');
      }

    } catch (error) {
      console.error("Error fetching coach list:", error);
    }
  };

  const fetchTrainings = async () => {
    try {
      let dayRequest = {
        type: "training",
        userId: 37,
        dateType: "day", // day, week, month
        // "fromDate": "2024-09-02T00:00:00",
        // "toDate": "2024-10-09T23:59:59"
        date: "2024-10-08"
      }
      const response: any = await fetchTrainingsListByDateRange(dayRequest);

      console.log(response?.data?.data, '-<,kjfak');

      if (response?.status == 200) {
        setTrainingList(response?.data?.data)

      } else if (response?.status == 400) {
        setError(response?.data?.message || 'error');
      } else {
        setError(response?.data?.message || 'error');
      }

    } catch (error) {
      console.error("Error fetching coach list:", error);
    }
  };

  function formatDateToYYMMDD(date: any = null) {
    const d = date == null ? new Date() : new Date(date);
    const year = String(d.getFullYear()); // Get last 2 digits of the year
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(d.getDate()).padStart(2, '0');
    // console.log(year,month,day)
    // setCalenderDate(`${year}-${month}-${day}`)
    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    dispatch(setInitialName("Manage Trainings"));

    const accessToken = localStorage.getItem("accessToken");

    if (!accessToken) {
      navigate("/auth");
    }
    // console.log(accessToken, '0<<accessToken')
    // Check if token is valid on initial load
    if (!isTokenValid(accessToken)) {
      // If token is invalid, redirect to login
      navigate("/auth");
    }

    formatDateToYYMMDD()
    const currentTimeFormat = getFormattedCurrentTime();
    setFormattedTime(currentTimeFormat)
    fetchDepartmentsList();
    fetchTrainings();
    setApiError('')

  }, [dispatch, refreshPage])


  // Map the training data to FullCalendar event format
  const events = trainingList.map((training: any, index) => {
    let backgroundColor = '';
    if (index % 3 === 0) {
      backgroundColor = 'blue';
    } else if (index % 3 === 1) {
      backgroundColor = 'red';
    } else {
      backgroundColor = 'green';
    }

    return {
      title: training.trainingTitle,
      start: training.startTime, // assuming training has a startTime field
      end: training.endTime, // assuming training has an endTime field
      backgroundColor: backgroundColor,
      borderColor: backgroundColor,
    };
  });


  const handleSelectDate = (date: any, type: any = 0) => {

    if (type === 1) {
      setDate(date);
      let dateVal: any = formatDateToYYMMDD(date);
      childRef.current.callChildFunction(dateVal);
    } else {
      const normalizedDate = new Date(date);
      normalizedDate.setHours(0, 0, 0, 0);

      setFormData(prevFormData => ({
        ...prevFormData,
        startDate: normalizedDate,
        endDate: null
      }));
    }
  };

  const handleMonthChange = (date: any) => {
    console.log('Month changed to:', date);
  }

  return (
    <>
      <TopNav />
      <div className="d-flex align-items-stretch">
        <SideNavBar />
        <div className="page-content pb-4">
          <div className="bredcrumb">
            <ul>
              <li><a href="#">Home</a></li>
              <li>Trainings</li>
            </ul>
          </div>
          <section className="blockWrap no-padding-bottom p-0">
            <div className="card">
              <Row className="g-0 calendarRow">
                <Col className="col app-calendar-sidebar">
                  <div className="stickySideBar">
                    <div className="border-bottom ps-4 pe-4 my-sm-0 mb-3">
                      <Button variant="primary" onClick={handleOpenFirstModal} className="d-flex w-100 align-items-center justify-content-between">
                        Create Training<span className="sign">+</span>
                      </Button>
                    </div>

                    <div className="calendarWrap p-4 border-bottom">
                      <ReactFlatpickr
                        value={date}
                        onChange={([dateVal]) => handleSelectDate(dateVal, 1)}
                        onMonthChange={handleMonthChange}

                        options={{ inline: true, dateFormat: 'Y-m-d' }}
                      />
                    </div>


                    <div className="p-4">
                      <div className="mb-3">
                        <small className="text-small text-muted text-uppercase align-middle fw-500">
                          Filter by Department
                        </small>
                      </div>
                      <Scrollbar
                        className="custom-scroll filterScroll"
                        style={{ maxHeight: '200px', paddingRight: '1rem' }}
                      >
                        {departments.map((department: any, index) => (
                          <Form.Check
                            key={department.id}
                            className="form-check-theme"
                            id={`department-${index}`}
                            label={department.departmentName || `Department ${index + 1}`}
                            onChange={(e) => handleDepartmentClick(department, e.target.checked)}
                            checked={selectedDepartments.some((dept) => dept.id === department.id)} // Controlled checked state
                          />
                        ))}
                      </Scrollbar>
                      <div className="mt-3">
                        <strong>Selected Departments:</strong>
                        {selectedDepartments.map((dept: any) => (
                          <span key={dept.departmentId} className="badge selectedFilter me-2">
                            {dept.departmentName}
                          </span>
                        ))}
                      </div>
                      {error && <p className="text-danger">{error}</p>}
                    </div>
                  </div>
                </Col>
                <Col className="col app-calendar-content">
                  <div className="fullCalendarWrap">
                    <CalendarComponent ref={childRef} refreshPageStatus={refreshPage} selectedDate={calenderDate} selectedDepartments={selectedDepartments} />
                    {error && <p className="error-message">{error}</p>}
                  </div>
                </Col>
              </Row>
            </div>
          </section >
          <Footer />
        </div >
      </div >

      {/* First Modal */}
      <Modal Modal className="mw-630" show={showFirstModal} onHide={handleCloseFirstModal} >
        <Modal.Header closeButton>
          {/* <Modal.Title>Create Training or Event</Modal.Title> */}
          <Modal.Title>Create Training</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <div className="form-group">
            <label>Training Program</label>
            <div className="custom-select-wrap">
              <input
                type="text"
                className="form-control"
                placeholder="Enter training program"
                value={formData.trainingProgram || ''}
                onChange={(e) => handleSelectChange('trainingProgram', e.target.value)}
              />
              {formErrors.trainingProgram && <div className="text-danger">{formErrors.trainingProgram}</div>}
            </div>
          </div>
          <div className="form-group">
            <label>Training Title</label>
            <input
              type="text"
              name="trainingTitle"
              value={formData.trainingTitle}
              onChange={handleInputChange}
              placeholder="Enter Training Title"
            />
            {formErrors.trainingTitle && <div className="text-danger">{formErrors.trainingTitle}</div>}
          </div>
          <div className="form-group">
            <label>Training Description</label>
            <textarea
              name="trainingDescription"
              value={formData.trainingDescription}
              onChange={handleInputChange}
              placeholder="Enter Training Description"
            />
            {formErrors.trainingDescription && <div className="text-danger">{formErrors.trainingDescription}</div>}
          </div>
          <Row>
            <Col lg="6">
              <div className="form-group">
                <label>Start Date</label>
                <MyDatePicker
                  value={formData.startDate || null}
                  onChange={(date) => handleSelectDate(date)}
                />
                {formErrors.startDate && <div className="text-danger">{formErrors.startDate}</div>}
              </div>
            </Col>
            <Col lg="6">
              <div className="form-group">
                <label>End Date</label>
                <MyDatePicker
                  value={formData.endDate || null}
                  onChange={(date: any) => setFormData({ ...formData, endDate: date })}
                />
                {formErrors.endDate && <div className="text-danger">{formErrors.endDate}</div>}
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <div className="form-group">
                <label>Start Time</label>
                <div className="iconFields">
                  <input
                    type="time"
                    name="startTime"
                    value={formData.startTime}
                    onChange={handleInputChange}
                  />
                  <i className='icon icon-clock fw-400'></i>
                </div>
                {formErrors.startTime && <div className="text-danger">{formErrors.startTime}</div>}
              </div>
            </Col>
            <Col lg="6">
              <div className="form-group">
                <label>End Time</label>
                <div className='iconFields'>
                  <input
                    type="time"
                    name="endTime"
                    value={formData.endTime}
                    onChange={handleInputChange}
                  />
                  <i className='icon icon-clock fw-400'></i>
                </div>
                {formErrors.endTime && <div className="text-danger">{formErrors.endTime}</div>}
              </div>
            </Col>
          </Row>
          <div className="form-group">
            <label>Department</label>
            <div className="custom-select-wrap">
              <Select
                className="selectpicker"
                placeholder="Select department"
                // options={optionsDepartment}
                options={optionsDepartment}
                onChange={(selectedOption) => handleSelectChange('department', selectedOption)}
              />
              {formErrors.department && <div className="text-danger">{formErrors.department}</div>}
            </div>
          </div>
          <div className="form-group">
            <label>Area & Location</label>
            <div className="custom-select-wrap">
              <input
                type="text"
                className="form-control"
                placeholder="Enter Area & Location"
                value={formData.area || ''}
                onChange={(e) => handleSelectChange('area', e.target.value)}
              />
              {formErrors.area && <div className="text-danger">{formErrors.area}</div>}
            </div>
          </div>

          {apiError && apiError != '' && <div className="text-danger">{apiError}</div>}
          {error && error != '' && <div className="text-danger">{error}</div>}

        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSubmit}>
            Create New Training
          </Button>
          <Button variant="secondary" onClick={handleCloseFirstModal}>
            Close
          </Button>

        </Modal.Footer>
      </Modal>
      {/* Second Modal */}
      <Modal className="mw-630" show={showSecondModal} onHide={handleCloseSecondModal}>
        <Modal.Header closeButton>
          <Modal.Title>Create Training</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="successBlock">
            <div className="ico">
              <i className="icon-success"></i>
            </div>
            <div className="cont">
              <p>New Training has been created successfully.</p>
            </div>
          </div>
          <div className="teBlock mt-2">
            <div className="cont">
              <div className="label">{formData.trainingTitle}</div>
              <div className="value fw-700"> {formData.trainingDescription}</div>
            </div>
            <div className="cont">
              <div className="label">Training Date</div>
              <div className="value fw-700">
                {formData.startDate ? new Date(formData.startDate).toLocaleDateString() : 'N/A'} -
                {formData.endDate ? new Date(formData.endDate).toLocaleDateString() : 'N/A'}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center bt-0 pt-0 pb-4">
          <Button variant="primary" onClick={handleCloseSecondModal}>Okay</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ManageTrainingEvents;