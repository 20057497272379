import Login from "./login";
import { msalInstance } from './msalConfig';
import { MsalProvider } from '@azure/msal-react';
import React from "react";

const Auth = () => {
  return (
    <div>
      <Login />
    </div>
  );
};

export default Auth;
