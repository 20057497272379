import { Card, Col, Row } from "@govtechsg/sgds-react";
import { useState, useEffect } from 'react';
import SideNavBar from "../../components/Sidebar-left/sideBar";
import TopNav from "../../components/top-nav";
import Footer from "../../components/footer";
import { Dropdown, Pagination, Modal, Button, Tab, Tabs, Toast, ToastContainer, ProgressBar, Alert } from "react-bootstrap";
import { addquestionnaire, fetchquestionnaire, deleteQuestionnaire } from "../../service/common.service";
import { isTokenValid } from "../../utils/TokenValid";
import { useNavigate } from "react-router-dom";





const CreateAssessments = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setRadioSections([{ id: 0, value: '' }]);
    setCheckboxSections([{ id: 0, value: '' }]);
    settextareaSelection(0);
    setratingSelection(0);
    setQuestionnaire('');
    setErrorMessage('');
    setShow(false);
  }
  const handleShow = () => setShow(true);
  const [showA, setShowA] = useState(true);
  const toggleShowA = () => setShowA(!showA);
  interface RadioSection {
    id: number;
    value: string;
  }
  interface checkboxSections {
    id: number;
    value: string;
  }
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [radioSections, setRadioSections] = useState<RadioSection[]>([{ id: 0, value: '' }]);
  const [checkboxSections, setCheckboxSections] = useState([{ id: 0, value: '' }]);
  const [textareaSelection, settextareaSelection] = useState(0);
  const [ratingSelection, setratingSelection] = useState(0);
  const [questionnaire, setQuestionnaire] = useState('');  // State to manage input value
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [apiError, setApiError] = useState('');
  const [assessmentQuestions, setAssessmentQuestions] = useState([]);
  const navigate = useNavigate();


  // // Fetch groups when the component mounts
  // useEffect(() => {

  //   const accessToken = localStorage.getItem("accessToken");

  //   if (!accessToken) {
  //     navigate("/auth");
  //   }
  //   // console.log(accessToken, '0<<accessToken')
  //   // Check if token is valid on initial load
  //   if (!isTokenValid(accessToken)) {
  //     // If token is invalid, redirect to login
  //     navigate("/auth");
  //   }
  // }, []);

  // Handle the dropdown selection change
  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setErrorMessage('');
    setSelectedOption(event.target.value); // Update selected option state
    if (event.target.value === 'textarea') {
      settextareaSelection(1);
    }
    if (event.target.value === 'ratings') {
      setratingSelection(1);
    }
  };

  //Radio button 
  const addNewRadioSection = () => {
    // Check if the last section's value is empty
    const lastSection = radioSections[radioSections.length - 1];
    if (lastSection.value.trim() === '') {
      setErrorMessage('Input cannot be empty');
    } else {
      setErrorMessage('');
      setRadioSections(prevSections => [
        ...prevSections,
        { id: prevSections.length, value: '' } // Add new section with an empty value
      ]);
    }
  };

  const addNewCheckboxSection = () => {
    const lastSection = checkboxSections[checkboxSections.length - 1];
    if (lastSection.value.trim() === '') {
      setErrorMessage('Input cannot be empty.');
    } else {
      setErrorMessage('');
      setCheckboxSections((prevSections) => [
        ...prevSections,
        { id: prevSections.length, value: '' }
      ]);
    }
  };

  // Handle text input changes for Radio
  const handleTextChange = (id: number, newValue: string) => {
    setRadioSections(prevSections =>
      prevSections.map(section =>
        section.id === id ? { ...section, value: newValue } : section
      )
    );
  };

  // Handle text input changes for checkboxes
  const handleCheckboxTextChange = (id: number, newValue: string) => {
    setCheckboxSections((prevSections) =>
      prevSections.map((section) =>
        section.id === id ? { ...section, value: newValue } : section
      )
    );
  };

  // Delete a radio section
  const deleteSection = (id: number) => {
    setRadioSections(prevSections => prevSections.filter(section => section.id !== id));
  };

  // Delete a checkbox section
  const deleteCheckboxSection = (id: number) => {
    setCheckboxSections((prevSections) => prevSections.filter((section) => section.id !== id));
  };


  useEffect((): any => {
    const questionsList = async () => {
      try {
        let dataRequest = {
          "assessmentId": 54
        }
        const response: any = await fetchquestionnaire(dataRequest);
        if (response?.status == 200) {
          setAssessmentQuestions(response?.data?.data);
        } else if (response?.status == 400) {
          setApiError(response?.data?.message || 'error');
        } else {
          setApiError(response?.data?.message || 'error');
        }

      } catch (error) {
        console.error("Error fetching Questions list:", error);
      }
    }
    questionsList();
  }, []);

  const handleSubmit = async () => {
    if (!questionnaire.trim()) {
      setErrorMessage('Questionnaire cannot be empty.');
      return;
    }
    const radioValues = radioSections.map(s => s.value.trim()).filter(v => v !== '');
    const checkboxValues = checkboxSections.map(s => s.value.trim()).filter(v => v !== '');
    let payload = null;
    switch (true) {
      case (radioValues.length === radioSections.length):
        setErrorMessage('');
        payload = { "assessmentId": 54, "questionnaire": questionnaire, "answerType": "radio_button", "answerValues": radioValues };
        break;
      case (checkboxValues.length === checkboxSections.length):
        setErrorMessage('');
        payload = { "assessmentId": 54, "questionnaire": questionnaire, "answerType": "checkbox", "answerValues": checkboxValues };
        break;
      case (ratingSelection === 1):
        setErrorMessage('');
        payload = { "assessmentId": 54, "questionnaire": questionnaire, "answerType": "ratings", "answerValues": [] };
        break;
      case (textareaSelection === 1):
        setErrorMessage('');
        payload = { "assessmentId": 54, "questionnaire": questionnaire, "answerType": "textarea", "answerValues": [] };
        break;
      default:
        setErrorMessage('Fields must be filled.');
    }

    if (payload !== null) {
      console.log("payload---------->", payload);
      try {
        let response = await addquestionnaire(payload);
        if (response?.status === 200) {
          setRadioSections([{ id: 0, value: '' }]);
          setCheckboxSections([{ id: 0, value: '' }]);
          settextareaSelection(0);
          setratingSelection(0);
          setQuestionnaire('');
          setShow(false);

        } else {
          setApiError(response?.data?.message || 'Error occurred');
        }
      } catch (error: any) {
        setApiError(error?.data?.message || 'Error occurred');
      }
    }
  };

  const handleDelete = async (questionId: number) => {
    const confirmed = window.confirm("Are you sure you want to delete this question?");
    if (!confirmed) {
      return;
    }
    try {
      const payload = {
        questionnaireId: questionId
      };
      const response = await deleteQuestionnaire(payload);
      if (response?.status === 200) {
        setApiError('');
      } else {
        setApiError(response?.data?.message || 'Error occurred while deleting the question');
      }
    } catch (error) {
      setApiError('Error occurred while deleting the question');
      console.error("Error deleting question:", error);
    }
  };

  return (
    <>
      <TopNav />
      <div className="d-flex align-items-stretch">
        <SideNavBar />
        <div className="page-content pb-4">
          <div className="bredcrumb">
            <ul>
              <li><a href="#">Home</a></li>
              <li><a href="#">Assessment</a></li>
              <li>Create New Assessment</li>
            </ul>
          </div>
          <section className="blockWrap no-padding-bottom p-0">
            <div className="card">
              <div className="card-header d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center ">
                  <div className="cardTitle">
                    <div className="label">Assessments</div>
                    <h5>Mental health monitoring</h5>
                  </div>

                  <div className="cardTitle">
                    <div className="label">Team</div>
                    <h5>Civil Squads</h5>
                  </div>
                </div>


                {/* <button className="btn btn-outline-primary">Save Assessment</button> */}
              </div>

              <div className="card-body pt-0">

                <div className="themeTabs">
                  <Tabs defaultActiveKey="add-edit" id="uncontrolled-tab-example">
                    <Tab eventKey="add-edit" title="Add & Edit">

                      {assessmentQuestions.map((record: any, index: number) => (
                        <div className="addEditBlockWrap">
                          <div className="addEditBlockFlex">
                            <div className="title">{index + 1}. {record.questionnaire}</div>
                            <div className="action">
                              <Button variant="outline-primary"><i className="icon-edit"></i></Button>
                              <Button variant="outline-primary" onClick={() => handleDelete(record.id)}><i className="icon-delete"></i></Button>
                            </div>
                          </div>
                        </div>
                      ))}

                      <div className="text-end mt-4 mb-2">
                        <Button variant="primary" onClick={handleShow}>Add New Questionnaire</Button>
                      </div>
                    </Tab>
                    <Tab eventKey="questionnaire" title="View Questionnaire">
                      <div className="viewQAWrap">
                        {assessmentQuestions.map((record: any, index: number) => (
                          <div className="addEditBlockWrap pe-4 ps-4 pt-3 pb-3">
                            <div className="addEditBlockFlex">
                              <div className="title fw-700">{index + 1}. {record.questionnaire}</div>
                              <div className="action">
                                <Button variant="outline-primary"><i className="icon-edit"></i></Button>
                                <Button variant="outline-primary" onClick={() => handleDelete(record.id)}><i className="icon-delete"></i></Button>
                              </div>
                            </div>
                            {record.answerType === 'radio_button' && (
                              <div className="slAnswerWrap">
                                {/* <label>Select any one answer</label> */}
                                <div className="inline-fields-wrap">
                                  {record.answerValues.map((value: string, idx: number) => (
                                    <div className="custom-check-field custom-radio" key={idx}>
                                      <input
                                        type="radio"
                                        name={`rate-${record.id}`}
                                        value={value}
                                      />
                                      <label>{value}</label>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}

                            {record.answerType === 'checkbox' && (
                              <div className="slAnswerWrap">
                                {/* <label>Select any one answer</label> */}
                                <div className="inline-fields-wrap">
                                  {record.answerValues.map((value: string, idx: number) => (
                                    <div className="custom-check-field" key={idx}>
                                      <input
                                        type="checkbox"
                                        name={`rate-${record.id}`}
                                        value={value}
                                      />
                                      <label>{value}</label>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}
                            {record.answerType === 'text_field' && (
                              <div className="slAnswerWrap">
                                <div className="inline-fields-wrap">
                                  <input
                                    type="text"
                                    id="name"
                                    style={{ width: '500px' }}
                                  />
                                </div>
                              </div>
                            )}
                            {record.answerType === 'ratings' && (
                              <div className="slAnswerWrap">
                                <label>Select any one answer</label>

                                <div className="inline-fields-wrap">
                                  {record.answerValues.split(',').map((value: string, idx: number) => (
                                    <div className="custom-check-field custom-radio" key={idx}>
                                      <input
                                        type="radio"
                                        name={`rate-${record.id}`}
                                        value={value}
                                      />
                                      {/* <label> ★</label> */}
                                      <label>{value} </label>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}


                          </div>
                        ))}
                      </div>
                    </Tab>
                    <Tab eventKey="result" title="View Results">
                      <Row>
                        <Col lg={3}>
                          <div className="rBlock">
                            <div className="label">Assigned</div>
                            <div className="value">200</div>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="rBlock">
                            <div className="label">Response</div>
                            <div className="value theme-color">180</div>
                          </div>
                        </Col>

                        <Col lg={3}>
                          <div className="rBlock">
                            <div className="label">Average time taken</div>
                            <div className="value ">4:00 mnts</div>
                          </div>
                        </Col>

                        <Col lg={3}>
                          <div className="rBlock">
                            <div className="label">Completion rate</div>
                            <div className="value color-success">88.7%</div>
                          </div>
                        </Col>

                      </Row>

                      <div className="filterBar mt-4">
                        <div className="title">Total Questions [4]</div>
                        <a className="link text-decoration-none theme-color fw-500" href="">View by users <i className="icon-arrow-right"></i></a>
                      </div>

                      <div className="viewQAWrap">
                        <div className="addEditBlockWrap pe-4 ps-4 pt-3 pb-3">
                          <div className="addEditBlockFlex">
                            <div className="title fw-700">1. How would you rate your overall mood today?</div>
                          </div>

                          <div className="progressWrap">
                            <div className="progressLinline">
                              <span>Very Good</span>
                              <ProgressBar variant="success" now={55} />
                              <span>145</span>
                            </div>

                            <div className="progressLinline">
                              <span>Good</span>
                              <ProgressBar variant="info" now={30} />
                              <span>25</span>
                            </div>

                            <div className="progressLinline">
                              <span>Neutral</span>
                              <ProgressBar now={56} />
                              <span>56</span>
                            </div>

                            <div className="progressLinline">
                              <span>Bad</span>
                              <ProgressBar now={12} variant="danger" />
                              <span>12</span>
                            </div>
                          </div>
                        </div>

                        <div className="addEditBlockWrap pe-4 ps-4 pt-3 pb-3">
                          <div className="addEditBlockFlex">
                            <div className="title fw-700">2. How often did you feel stressed today?</div>
                          </div>

                          <div className="progressWrap">
                            <div className="progressLinline">
                              <span>Very Good</span>
                              <ProgressBar variant="success" now={55} />
                              <span>145</span>
                            </div>

                            <div className="progressLinline">
                              <span>Good</span>
                              <ProgressBar variant="info" now={30} />
                              <span>25</span>
                            </div>

                            <div className="progressLinline">
                              <span>Neutral</span>
                              <ProgressBar now={56} />
                              <span>56</span>
                            </div>

                            <div className="progressLinline">
                              <span>Bad</span>
                              <ProgressBar now={12} variant="danger" />
                              <span>12</span>
                            </div>

                          </div>
                        </div>


                        <div className="addEditBlockWrap pe-4 ps-4 pt-3 pb-3">
                          <div className="addEditBlockFlex">
                            <div className="title fw-700">3. Did you feel any of the following emotions today? </div>
                          </div>

                          <div className="progressWrap">
                            <div className="progressLinline">
                              <span>Very Good</span>
                              <ProgressBar variant="success" now={55} />
                              <span>145</span>
                            </div>

                            <div className="progressLinline">
                              <span>Good</span>
                              <ProgressBar variant="info" now={30} />
                              <span>25</span>
                            </div>

                            <div className="progressLinline">
                              <span>Neutral</span>
                              <ProgressBar now={56} />
                              <span>56</span>
                            </div>

                            <div className="progressLinline">
                              <span>Bad</span>
                              <ProgressBar now={12} variant="danger" />
                              <span>12</span>
                            </div>

                          </div>
                        </div>


                      </div>

                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </section>

          <Footer />
        </div>
      </div>

      <Modal className="mw-530" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Questionnaire</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <div className="form-group">
            <label>Questionnaire</label>
            <input type="text" value={questionnaire} placeholder="Enter Questionnaire" onChange={(e) => { setQuestionnaire(e.target.value); setErrorMessage(''); }} // Update state on change
            />
          </div>
          <div className="form-group">
            <label>Answer type</label>
            <select onChange={handleSelectChange} value={selectedOption}>
              <option value="">--Select--</option>
              <option value="radioButton">Radio button</option>
              <option value="checkBoxes">Check Boxes</option>
              <option value="textarea">Textarea</option>
              <option value="ratings">Ratings</option>
            </select>
          </div>

          <div className="answerTypeBg">
            {/* Conditionally render the radio button input fields */}
            {selectedOption === 'radioButton' && (
              <>
                {radioSections.map((section, index) => (
                  <div key={section.id} className="answerTypeWrap">
                    <div className="answerType">
                      <div className="">
                        <input
                          type="text"
                          value={section.value}
                          name={`Answertype-${section.id}`}
                          onChange={(e) => handleTextChange(section.id, e.target.value)}
                        />
                      </div>
                    </div>
                    {index !== 0 && (
                      <div className="action">
                        <Button variant="outline-secondary" onClick={() => deleteSection(section.id)}>
                          <i className="icon-delete"></i>
                        </Button>
                      </div>
                    )}
                  </div>
                ))}
                <div className="text-center mt-3">
                  <Button variant="link" className="color-primary p-0 text-decoration-none" onClick={addNewRadioSection}>
                    Add New +
                  </Button>
                </div>
              </>
            )}

            {/* Conditionally render checkbox input fields */}
            {selectedOption === 'checkBoxes' && (
              <>
                {checkboxSections.map((section, index) => (
                  <div key={section.id} className="answerTypeWrap">
                    <div className="answerType">
                      <div className="">
                        <input
                          type="text"
                          value={section.value}
                          name={`Answertype-${section.id}`}
                          onChange={(e) => handleCheckboxTextChange(section.id, e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="action">
                      {/* Render delete button only for sections other than the first */}
                      {index !== 0 && (
                        <Button variant="outline-secondary" onClick={() => deleteCheckboxSection(section.id)}>
                          <i className="icon-delete"></i>
                        </Button>
                      )}
                    </div>
                  </div>
                ))}
                <div className="text-center mt-3">
                  <Button variant="link" className="color-primary p-0 text-decoration-none" onClick={addNewCheckboxSection}>
                    Add New +
                  </Button>
                </div>
              </>
            )}
          </div>

          {/* Show error message if there is one */}
          {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
        </Modal.Body>
        <Modal.Footer className="bt-0 pt-3 mt-1 pb-4">
          <Button variant="primary" onClick={handleSubmit}>
            Add New Questionnaire
          </Button>
          <Button className="me-0" variant="outline-secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <ToastContainer className="position-fixed">
      <Toast className="success" show={showA} onClose={toggleShowA}>
          <Toast.Header>
            <i className="icon-success"></i>
            <strong className="me-auto">Success</strong>
            <small>11 mins ago</small>
          </Toast.Header>
          <Toast.Body>Woohoo, you're reading this text in a Toast!</Toast.Body>
        </Toast>
      </ToastContainer> */}
    </>
  );
};

export default CreateAssessments;