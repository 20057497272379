import React, { useEffect } from 'react';
import { Card, Col, Row } from "@govtechsg/sgds-react";
import { useState } from 'react';
import SideNavBar from "../../components/Sidebar-left/sideBar";
import TopNav from "../../components/top-nav";
import Footer from "../../components/footer";
import { Dropdown, Form, Modal, Button, Table, Badge, Pagination } from "react-bootstrap";
import Select from 'react-select';
import BarChart from '../../components/chart';
import { fetchDepartment, getHeartRateZoneList, getTrainingListByDpId } from '../../service/common.service';

import { isTokenValid } from "../../utils/TokenValid";
import { useNavigate } from "react-router-dom";



const LiveTrainingTracking = () => {

  const [show, setShow] = useState(false);


  const [departments, setDepartments] = useState([]);
  const [trainingList, setTrainingList] = useState([]);
  const [error, setApiError] = useState('')
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(8);
  const [totalPages, setTotalPages] = useState(1);
  const [pageLoader, setPageLoader] = useState(0)
  const [hrzList, setHrzList] = useState([])
  const [trainingId, setTrainingId] = useState('');
  const [skipStatus, setskipStatus] = useState(false)
  const [selectedDepartment, setSelectedDepartmnt] = useState(null);
  const [selectedTraining, setSelectedTraining] = useState(null);
  const [dpStatus, setDeptStatus] = useState(true)
  const [trainingStatus, setTrainingStatus] = useState('')
  const navigate = useNavigate();


  //   const getDepartmentList=async ()=>{
  //       try {
  //           const response: any = await fetchDepartment();
  //           // console.log('response?.data?.data>>',response?.data?.data)
  //           if (response?.status == 200) {

  //             if(response?.data?.data.length>0){
  //               let revisedValues= response?.data?.data.map((record:any)=>{
  //                   return {value:record.id,label:record.departmentName}
  //               })
  //               setDepartments(revisedValues)
  //             }

  //           } else if (response?.status == 400) {
  //             setApiError(response?.data?.message || 'error');
  //           } else {
  //               setApiError(response?.data?.message || 'error');
  //           }

  //         } catch (error) {
  //           console.error("Error fetching coach list:", error);
  //         }
  //   }
  //  getDepartmentList()

  // let i =0

  useEffect(() => {

    const accessToken = localStorage.getItem("accessToken");

    if (!accessToken) {
      navigate("/auth");
    }
    // Check if token is valid on initial load
    if (!isTokenValid(accessToken)) {
      // If token is invalid, redirect to login
      navigate("/auth");
    }

    const getDepartmentList = async () => {
      try {
        const response: any = await fetchDepartment();
        // console.log('response?.data?.data>>',response?.data?.data)
        if (response?.status == 200) {

          if (response?.data?.data.length > 0) {
            let revisedValues = response?.data?.data.map((record: any) => {
              return { value: record.id, label: record.departmentName }
            })
            setDepartments(revisedValues)
          }

        } else if (response?.status == 400) {
          setApiError(response?.data?.message || 'error');
        } else {
          setApiError(response?.data?.message || 'error');
        }

      } catch (error) {
        console.error("Error fetching coach list:", error);
      }
    }

    // get page loader condionally
    if (dpStatus || skipStatus)
      setPageLoader(1)

    // here the departmentList will only be called once upon initial loading
    if (!skipStatus) {
      getDepartmentList()

    }

    //console.log('test-0')

    // getHeartRateZoneListRecords();

    // setPageLoader(1)


    // revised
    //   //   Set the interval inside useEffect to trigger after 5 seconds
    //     const timer = setInterval(async() => {
    //       console.log('test-0a')

    //      const completeStatus:any=await getHeartRateZoneListRecords();
    //      console.log('completeStatus>>',completeStatus)

    //       if(completeStatus=='completed'){
    //         clearInterval(timer);
    //       }

    //       setPageLoader(0)
    //     }, 7000); // 5 seconds delay

    //  //   getHeartRateZoneListRecords()

    //  //   Cleanup the interval if the component unmounts
    //     return () => clearInterval(timer);

    //     //   Set the interval inside useEffect to trigger after 5 seconds
    //     const timer = setInterval(async() => {
    //       console.log('test-0a')

    //      const completeStatus:any=await getHeartRateZoneListRecords();
    //      console.log('completeStatus>>',completeStatus)

    //       if(completeStatus=='completed'){
    //         clearInterval(timer);
    //       }

    //       setPageLoader(0)
    //     }, 7000); // 5 seconds delay

    //  //   getHeartRateZoneListRecords()

    //  //   Cleanup the interval if the component unmounts
    //     return () => clearInterval(timer);



    // get Heart Rate zone list
    let timer: any;
    const fetchInitialHRZList = async () => {

      try {

        // Wait for the promise to resolve
        const completeStatus: any = await getHeartRateZoneListRecords();
        // console.log('completeStatus>>')

        // check if completeStatus of training is 'on_going' then only run the setInterval after 5 sec to fetch live training data
        if (completeStatus === 'on_going') {
          timer = setInterval(async () => {

            const completeStatus: any = await getHeartRateZoneListRecords();
            // console.log('completeStatus>>',completeStatus)

            // check if completeStatus is completed then clear the setInterval
            if (completeStatus == 'completed') {
              clearInterval(timer);
            }

            setPageLoader(0)
          }, 5000); // 5 seconds delay
        }

        // Update state based on the result
        setPageLoader(0);  // Hide loader when done

      } catch (error) {
        console.error('Error fetching heart rate data:', error);
        setPageLoader(0);  // Hide loader on error as well
      }
    };

    // Call the async function
    fetchInitialHRZList();

    return () => clearInterval(timer);

  }, [trainingId, currentPage, dpStatus])


  const getHeartRateZoneListRecords = async () => {
    try {

      // setPageLoader(1)

      console.log('test-1')
      const payload = {
        page: currentPage,
        limit: itemsPerPage,
        order: 'DESC',
        trainingId: trainingId,
        status: 'on_going'
      };

      const response: any = await getHeartRateZoneList(payload);

      if (response?.status === 200) {
        if (response?.data?.data?.listItems.length > 0) {

          // by default selecting  the dropdown and fetch trainingList on initial loading
          if (response?.data?.data?.departmentRecord != null && dpStatus) {
            setSelectedDepartmnt(response?.data?.data?.departmentRecord)
            setSelectedTraining(response?.data?.data?.trainingRecord)
            await setTrainingStatus(response?.data?.data?.completeStatus)
            await getTrainingList(response?.data?.data?.departmentRecord?.value)

          }
          setHrzList(response?.data?.data?.listItems);
          await setTrainingStatus(response?.data?.data?.completeStatus)
          setTotalPages(response?.data?.data?.totalPages || 1)
          setPageLoader(0)


          return response?.data?.data?.completeStatus
        }
        else {
          setPageLoader(0)
          setHrzList([])
        }
      } else if (response?.status === 400) {
        setApiError(response?.data?.message || 'error');
      } else {
        setApiError(response?.data?.message || 'error');
      }
    } catch (error) {
      console.error('Error fetching coach list:', error);
      setApiError('An error occurred while fetching the data.');
      setPageLoader(0)

    }
  };

  // Handle page change
  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  };

  const onDepartmentSelect = async (e: any) => {

    setDeptStatus(false)
    setSelectedDepartmnt(e)
    await getTrainingList(e.value)

  }

  const getTrainingList = async (departmntId: number) => {
    try {
      const response: any = await getTrainingListByDpId({ departmentId: departmntId });
      if (response?.status == 200) {
        if (response?.data?.data.length > 0) {
          let revisedValues = response?.data?.data.map((record: any) => {
            return { value: record.trainingId, label: record.trainingTitle }
          })
          setTrainingList(revisedValues)
        }
        else {
          setTrainingList([])

        }

      } else if (response?.status == 400) {
        setApiError(response?.data?.message || 'error');
      } else {
        setApiError(response?.data?.message || 'error');
      }

    } catch (error) {
      console.error("Error fetching coach list:", error);
    }
  }

  const onTrainingSelect = async (e: any) => {

    setSelectedTraining(e)
    setTrainingId(e.value)
    setskipStatus(true)



  }

  return (
    <>
      <TopNav />
      <div className="d-flex align-items-stretch">
        <SideNavBar />
        <div className="page-content pb-4">
          <div className="bredcrumb">
            <ul>
              <li><a href="#">Home</a></li>
              <li>Live Training Data</li>
            </ul>
          </div>
          <section className="blockWrap no-padding-bottom p-0">
            <div className="card">
              <div className="card-header d-flex align-items-center justify-content-between border-bottom-0">
                <div className="cardTitle mx-w-580">
                  <h5>Live Training Data</h5>
                  <p>View the live training health data</p>
                </div>
                <div className="tableFilterWrap">
                  <div className="custom-select-wrap">
                    <Select
                      value={selectedDepartment != null ? selectedDepartment : null}
                      className="selectpicker" onChange={onDepartmentSelect} placeholder="Select Department"
                      options={departments}

                    />

                  </div>


                  <div className="custom-select-wrap ms-md-3">
                    <Select className="selectpicker"
                      value={selectedTraining != null ? selectedTraining : null}
                      onChange={onTrainingSelect}
                      placeholder="Select Training Name" options={trainingList} />
                  </div>

                </div>
              </div>

              <div className="labelBar">
                <span className="zone1">Very Light Warm Up Zone</span>
                <span className="zone2">Light Fat Burn Zone</span>
                <span className="zone3">Moderate Aerobic Zone</span>
                <span className="zone4">Hard Anaerobic Zone</span>
                <span className="zone5">Maximum VO2 Max Zone</span>
              </div>

              {
                pageLoader == 1 ?
                  <div className="pageLoader">
                    <span className="spinner"></span>
                  </div>
                  :
                  <div className="card-body pt-0">

                    {/* <div className="durationTitle">
                    <i className="icon-clock"></i> DURATION : <span className="color-danger">49:34</span>
                  </div>


                  <div className="totalDataBloksWrap">
                    <div className="totalDataBloks">
                      <div className="number">130</div>
                      <div className="Cont">AVG <br/> Heart Rate</div>
                    </div>
                    <div className="totalDataBloks">
                      <div className="number">4,320</div>
                      <div className="Cont">Total <br/> Calories</div>
                    </div>
                    <div className="totalDataBloks">
                      <div className="number">130</div>
                      <div className="Cont">AVG <br/> Intensity</div>
                    </div>
                  </div> */}

                    {/* <Row className="gridd20">
                  
                    <Col lg="3">
                        <div className="trackingGraphBlock">
                          <div className="trackingGraphHead">
                              <i className="icon-user"></i> Tony phe2
                          </div>

                          <div className="trackingGraphBody">
                              <div className="d-flex align-items-center justify-content-between">
                                  <div className="trackingData">
                                    <label>AVG. Intensity</label>
                                    <div className="val">62</div>
                                  </div>
                                  <div className="trackingData">
                                    <label>Avg. HR</label>
                                    <div className="val">139</div>
                                  </div>
                              </div>
                              <div className="d-flex align-items-center justify-content-between">
                                  <div className="trackingData">
                                    <label>Calories</label>
                                    <div className="val">225</div>
                                  </div>
                                  <div className="trackingData">
                                    <label>Max.HR</label>
                                    <div className="val">171</div>
                                  </div>
                              </div>
                              <div className='chartDetailsWrap'>
                                <div className='chartBarWrap'>
                                  <BarChart />
                                </div>

                                <div className='chartDetailsBotom'>
                                  <span>01:20</span>
                                  <span>05:24</span>
                                  <span>04:13</span>
                                  <span>02:46</span>
                                  <span>03:00</span>
                                </div>
                              </div>
                              
                          </div>
                        </div>
                    </Col>

                  </Row> */}

                    <Row className="gridd20">
                      {
                        hrzList.length > 0 && hrzList.map((record: any) => {
                          return (
                            record != null && (
                              <>
                                <Col md="6" lg="4" xl="3" key={record.countVal}>
                                  <div className="trackingGraphBlock">
                                    <div className="trackingGraphHead">
                                      <div className='text'>
                                        <i className="icon-user"></i> {record?.userName || ''}
                                      </div>
                                      <div className="trackingData">
                                        <label>MHR</label>
                                        <div className="val">{record?.maxHeartRate || 0}</div>
                                      </div>
                                    </div>

                                    <div className="trackingGraphBody">
                                      <div className="d-flex align-items-center justify-content-between">

                                        <div className="trackingData">
                                          <label>{record?.status === 'completed' ? 'Avg .' : ''} HR</label>
                                          {record?.status === 'completed' ?
                                            <div className="val">{record?.avgHeartRate || 0}</div>
                                            :
                                            <div className="val">{record?.heartRate || 0}</div>
                                          }
                                        </div>
                                        <div className="trackingData">
                                          <label>{record?.status === 'completed' ? 'Avg .' : ''} Intensity</label>
                                          {/* <div className="val">{record?.avgIntensity || 0}</div> */}
                                          {record?.status === 'completed' ?
                                            <div className="val">{record?.avgIntensity || 0}</div>
                                            :
                                            <div className="val">{record?.intensityAtTime || 0}</div>
                                          }
                                        </div>
                                        <div className="trackingData">
                                          <label>Peak HR</label>
                                          <div className="val">{record?.peakHeartRate || 0}</div>
                                        </div>
                                      </div>
                                      <div className="d-flex br-top justify-content-between">
                                        <div className="trackingData">
                                          <label>Steps</label>
                                          <div className="val">{record?.stepCount || 0}</div>
                                        </div>
                                        <div className="trackingData">
                                          <label>Distance</label>
                                          <div className="val">{record?.distance || 0} m</div>
                                        </div>
                                        <div className="trackingData">
                                          <label>Calories</label>
                                          <div className="val">{record?.caloriesCount || 0}</div>
                                        </div>
                                      </div>
                                      <div className="d-flex track-m-n justify-content-between mt-2">
                                        {record?.totalDurationInMinutes !== '00:00' &&
                                          <div className="trackingData">
                                            <label>Duration</label>
                                            <div className="val">{record?.totalDurationInMinutes || 0} m</div>
                                          </div>
                                        }
                                        {/* <div className="trackingData">
                                          <label>Peak HR</label>
                                          <div className="val">{record?.peakHeartRate || 0}</div>
                                        </div> */}
                                      </div>
                                      <div className='chartDetailsWrap'>
                                        <div className='chartBarWrap'>
                                          <BarChart hrzRecord={record.heartRateZoneCounts} />
                                        </div>

                                        <div className='chartDetailsBotom'>
                                          {
                                            record?.heartRateZoneCounts.map((record: any) => (
                                              <span>{record?.timeSpent}</span>
                                            ))

                                          }
                                          {/* <span>01:20</span>
                                            <span>05:24</span>
                                            <span>04:13</span>
                                            <span>02:46</span>
                                            <span>03:00</span> */}
                                        </div>
                                        <div className="trackingData" style={{ textAlign: 'center' }}>
                                          <label>Minutes</label>
                                        </div>

                                      </div>

                                    </div>
                                  </div>
                                </Col>
                              </>
                            )
                          )
                        }

                        )
                      }
                      {
                        hrzList.length === 0 && (

                          <div className="cardTitle mx-w-580 m-auto mt-5 mb-4 text-center">
                            <h5>No Data Available</h5>
                          </div>

                        )
                      }


                    </Row>

                    {
                      hrzList.length > 0 && (
                        <div className="mt-4 mb-2 justify-content-center d-flex align-items-center">
                          <Pagination className="mb-0">
                            <Pagination.Prev className="prev"
                              onClick={() => handlePageChange(currentPage - 1)}
                              disabled={currentPage === 1}
                            />
                            <Pagination.Item
                              active={currentPage === 1}
                              onClick={() => handlePageChange(1)}
                            >
                              {1}
                            </Pagination.Item>

                            {/* Show ellipsis if current page is beyond page 3 */}
                            {currentPage > 3 && <Pagination.Ellipsis />}

                            {/* Show up to 2 pages before and 2 after the current page */}
                            {Array.from({ length: 5 })
                              .map((_, index) => currentPage - 2 + index) // Generate up to 5 pages: two before and two after
                              .filter(
                                (pageNumber) =>
                                  pageNumber > 1 && pageNumber < totalPages // Ensure valid page numbers between first and last
                              )
                              .map((pageNumber) => (
                                <Pagination.Item
                                  key={pageNumber}
                                  active={pageNumber === currentPage}
                                  onClick={() => handlePageChange(pageNumber)}
                                >
                                  {pageNumber}
                                </Pagination.Item>
                              ))}

                            {/* Show ellipsis if there are more than 3 pages remaining */}
                            {currentPage < totalPages - 2 && <Pagination.Ellipsis />}

                            {/* Always show the last page */}
                            {totalPages > 1 && (
                              <Pagination.Item
                                active={currentPage === totalPages}
                                onClick={() => handlePageChange(totalPages)}
                              >
                                {totalPages}
                              </Pagination.Item>
                            )}


                            {/* Next page button */}
                            <Pagination.Next
                              className="next"
                              onClick={() => handlePageChange(currentPage + 1)}
                              disabled={currentPage === totalPages}
                            />


                            {/* <Pagination.Item>{1}</Pagination.Item>
                          <Pagination.Item active>{2}</Pagination.Item>
                          <Pagination.Item>{3}</Pagination.Item>
                          <Pagination.Item>{4}</Pagination.Item>
                          <Pagination.Ellipsis />
                          <Pagination.Next className="next" /> */}
                          </Pagination>
                        </div>

                      )
                    }





                  </div>
              }





            </div>


          </section>

          <Footer />
        </div>
      </div>


    </>
  );
};

export default LiveTrainingTracking;
