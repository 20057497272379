import { useState } from 'react';
import { Card, Col, Row } from "@govtechsg/sgds-react";
import SideNavBar from "../../components/Sidebar-left/sideBar";
import TopNav from "../../components/top-nav";
import Footer from "../../components/footer";
import { Button, Form, Toast, ToastContainer } from "react-bootstrap";
import Select from 'react-select';


const options = [
  { value: 'Dashboard', label: 'Dashboard' },
  { value: 'Monitoring & Alerts', label: 'Monitoring & Alerts' },
  { value: 'Health Data', label: 'Health Data' }
]


const CreateRoles = () => {

  const [showA, setShowA] = useState(false);
  const toggleShowA = () => setShowA(!showA);
  
  return (
    <>
      <TopNav />
      <div className="d-flex align-items-stretch">
        <SideNavBar />
        <div className="page-content pb-4">
          <div className="bredcrumb">
            <ul>
              <li><a href="#">Home</a></li>
              <li><a href="#">Roles</a></li>
              <li>Create New Role</li>
            </ul>
          </div>
          <section className="blockWrap no-padding-bottom p-0">
            <div className="card">
              <div className="card-header d-flex align-items-center justify-content-between border-bottom-0 pb-0">
                  <div className="cardTitle mx-w-580">
                    <h5>Map Role</h5>
                  </div>
              </div>

              <div className="card-body">
                  <Row>
                    <Col lg="6">
                      <div className="form-group d-light">
                        <label>Role Title</label>
                        <input type="text" value="Sub Admin" placeholder="Enter Role Title" />
                      </div>
                    </Col>

                    <Col lg="6">
                      <div className="form-group d-light">
                        <label>Role Description</label>
                        <input type="text" value="Mange the Health data, Trainings, Events and User Management" placeholder="Enter Role Description" />
                      </div>
                    </Col>
                  </Row>
                  
                  <div className="componentBlock">
                    <div className="componentHeader">
                      <div className="title">
                        <h5>Module Access</h5>
                      </div>
                      <div className="action">
                        <Select className="selectpicker" placeholder="Select role type" options={options} />
                      </div>
                    </div>
                    <div className="componentBody">
                      <Row>
                        <Col lg="4">
                          <div className="roleNameBlock">
                            <div className="roleNameBlockhead">
                                <h6>Dashboard</h6>
                                <Form.Check  type="switch" id="custom-switch" label="Off" />
                            </div>
                            <div className="roleNameBlockBody">
                              <div className="inline-fields-wrap">
                                  <div className="custom-check-field">
                                    <input type="checkbox" name="Dashboard" value="Create" />
                                    <label>Create</label>
                                  </div>

                                  <div className="custom-check-field">
                                    <input type="checkbox" name="Dashboard" value="View" />
                                    <label>View</label>
                                  </div>

                                  <div className="custom-check-field">
                                    <input type="checkbox" name="Dashboard" value="Edit" />
                                    <label>Edit</label>
                                  </div>

                                  <div className="custom-check-field">
                                    <input type="checkbox" name="Dashboard" value="Delete" />
                                    <label>Delete</label>
                                  </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col lg="4">
                          <div className="roleNameBlock">
                            <div className="roleNameBlockhead">
                                <h6>Monitoring & Alerts</h6>
                                <Form.Check  type="switch" id="alerts-switch" label="Off" />
                            </div>
                            <div className="roleNameBlockBody">
                              <div className="inline-fields-wrap">
                                  <div className="custom-check-field">
                                    <input type="checkbox" name="Dashboard" value="Create" />
                                    <label>Create</label>
                                  </div>

                                  <div className="custom-check-field">
                                    <input type="checkbox" name="Dashboard" value="View" />
                                    <label>View</label>
                                  </div>

                                  <div className="custom-check-field">
                                    <input type="checkbox" name="Dashboard" value="Edit" />
                                    <label>Edit</label>
                                  </div>

                                  <div className="custom-check-field">
                                    <input type="checkbox" name="Dashboard" value="Delete" />
                                    <label>Delete</label>
                                  </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col lg="4">
                          <div className="roleNameBlock">
                            <div className="roleNameBlockhead">
                                <h6>Health Data</h6>
                                <Form.Check  type="switch" id="health-data-switch" label="Off" />
                            </div>
                            <div className="roleNameBlockBody">
                              <div className="inline-fields-wrap">
                                  <div className="custom-check-field">
                                    <input type="checkbox" name="Dashboard" value="Create" />
                                    <label>Create</label>
                                  </div>

                                  <div className="custom-check-field">
                                    <input type="checkbox" name="Dashboard" value="View" />
                                    <label>View</label>
                                  </div>

                                  <div className="custom-check-field">
                                    <input type="checkbox" name="Dashboard" value="Edit" />
                                    <label>Edit</label>
                                  </div>

                                  <div className="custom-check-field">
                                    <input type="checkbox" name="Dashboard" value="Delete" />
                                    <label>Delete</label>
                                  </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col lg="4">
                          <div className="roleNameBlock">
                            <div className="roleNameBlockhead">
                                <h6>Training & Events</h6>
                                <Form.Check  type="switch" id="training-events-switch" label="Off" />
                            </div>
                            <div className="roleNameBlockBody">
                              <div className="inline-fields-wrap">
                                  <div className="custom-check-field">
                                    <input type="checkbox" name="Dashboard" value="Create" />
                                    <label>Create</label>
                                  </div>

                                  <div className="custom-check-field">
                                    <input type="checkbox" name="Dashboard" value="View" />
                                    <label>View</label>
                                  </div>

                                  <div className="custom-check-field">
                                    <input type="checkbox" name="Dashboard" value="Edit" />
                                    <label>Edit</label>
                                  </div>

                                  <div className="custom-check-field">
                                    <input type="checkbox" name="Dashboard" value="Delete" />
                                    <label>Delete</label>
                                  </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col lg="4">
                          <div className="roleNameBlock">
                            <div className="roleNameBlockhead">
                                <h6>User Managements</h6>
                                <Form.Check  type="switch" id="user-managements-switch" label="Off" />
                            </div>
                            <div className="roleNameBlockBody">
                              <div className="inline-fields-wrap">
                                  <div className="custom-check-field">
                                    <input type="checkbox" name="Dashboard" value="Create" />
                                    <label>Create</label>
                                  </div>

                                  <div className="custom-check-field">
                                    <input type="checkbox" name="Dashboard" value="View" />
                                    <label>View</label>
                                  </div>

                                  <div className="custom-check-field">
                                    <input type="checkbox" name="Dashboard" value="Edit" />
                                    <label>Edit</label>
                                  </div>

                                  <div className="custom-check-field">
                                    <input type="checkbox" name="Dashboard" value="Delete" />
                                    <label>Delete</label>
                                  </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col lg="4">
                          <div className="roleNameBlock">
                            <div className="roleNameBlockhead">
                                <h6>Assessments</h6>
                                <Form.Check  type="switch" id="assessments-switch" label="Off" />
                            </div>
                            <div className="roleNameBlockBody">
                              <div className="inline-fields-wrap">
                                  <div className="custom-check-field">
                                    <input type="checkbox" name="Dashboard" value="Create" />
                                    <label>Create</label>
                                  </div>

                                  <div className="custom-check-field">
                                    <input type="checkbox" name="Dashboard" value="View" />
                                    <label>View</label>
                                  </div>

                                  <div className="custom-check-field">
                                    <input type="checkbox" name="Dashboard" value="Edit" />
                                    <label>Edit</label>
                                  </div>

                                  <div className="custom-check-field">
                                    <input type="checkbox" name="Dashboard" value="Delete" />
                                    <label>Delete</label>
                                  </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>

                  <div className="text-end mt-3">
                  <a href="roles"  className="btn btn-primary mn-w-148" onClick={toggleShowA}>Create New Role</a>
                  </div>
                  
              </div>
              
              

              
            </div>


          </section>

          <Footer />
        </div>
      </div>

      <ToastContainer className="position-fixed">
      <Toast className="success" show={showA} onClose={toggleShowA}>
          <Toast.Header>
            <i className="icon-success"></i>
            <strong className="me-auto">Success</strong>
            <small>11 mins ago</small>
          </Toast.Header>
          <Toast.Body>Woohoo, you're reading this text in a Toast!</Toast.Body>
        </Toast>
      </ToastContainer>
      
    </>
  );
};

export default CreateRoles;
