import { Outlet, useLocation } from "react-router-dom";
// import { Box, Container } from "@chakra-ui/react";


const Layout = () => {
  const location = useLocation();

  return (
    <>
      {location.pathname === "/greeting" ||
      location.pathname === "/unauthorized" ||
      location.pathname === "/404" ? null : (
        <div className="mainWrap"></div>
      )}

      <div className="main-wrap">
        <div className="">
          {/* pt={location.pathname === "/auth" || location.pathname === "/unauthorized" ? 0 : "80px"} */}
          <div>
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};


export default Layout;
