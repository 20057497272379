import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid'; // For month view
import timeGridPlugin from '@fullcalendar/timegrid'; // For week and day views
import listPlugin from '@fullcalendar/list'; // For list view
import { EventClickArg, DatesSetArg } from '@fullcalendar/core';
import { format, startOfMonth, endOfMonth } from 'date-fns';
import { fetchTrainingsListByDateRange, cancelTrainingApi, fetchDepartment, updateTraining, getFormattedCurrentTime } from '../../service/common.service';

import { Card, Col, Row } from "@govtechsg/sgds-react";
import { Container, Dropdown, Pagination, Modal, Button, Form } from "react-bootstrap";
import Select from 'react-select';
import 'flatpickr/dist/flatpickr.css';
import MyDatePicker from "../../components/datepicker";
import moment from 'moment';
import { Tooltip } from "bootstrap";


const options = [
  { value: 'Outdoor Training', label: 'Outdoor Training' },
  { value: 'Indoor Training', label: 'Indoor Training' }
];

const optionsArea = [
  { value: 'Ground 1', label: 'Ground 1' },
  { value: 'Ground 2', label: 'Ground 2' }
];


interface Training {
  id: string;
  trainingTitle: string;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  attendanceStatus: string;
  trainingDescription: string;
  trainingType: string;
  trainingProgram: string;
  status: string | null;
}

interface FormData {
  id: string,
  trainingTitle: string;
  trainingDescription: string;
  startDate: Date | null; // Ensure it allows null
  endDate: Date | null; // Ensure it allows null
  startTime: string;
  endTime: string;
  departmentName: string;
  trainingType: string;
  trainingProgram: string;
  area: string;
  departmentId: number;
}

interface calenderProps {
  refreshPageStatus: boolean,
  selectedDepartments: any,
  selectedDate: any,
  // ref:any
}
export interface CalendarHandle {
  // Define any functions you want to expose to the parent
  callChildFunction: (dateValue: string) => void;
}

// const CalendarComponent: React.FC<calenderProps> = forwardRef(({ refreshPageStatus, selectedDepartments,selectedDate},ref) => {
const CalendarComponent = forwardRef<CalendarHandle, calenderProps>(
  ({ refreshPageStatus, selectedDepartments, selectedDate }, ref) => {

    const [trainingList, setTrainingList] = useState<Training[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [currentView, setCurrentView] = useState('dayGridMonth'); // Track the current view
    const [currentDate, setCurrentDate] = useState(new Date()); // Track the current date
    const [showFirstModal, setShowFirstModal] = useState(false);
    const [formData, setFormData] = useState<FormData>({
      id: '',
      trainingTitle: '',
      trainingDescription: '',
      startDate: null,
      endDate: null,
      startTime: '',
      endTime: '',
      departmentName: '',
      trainingType: '',
      trainingProgram: '',
      area: '',
      departmentId: 0
    });

    const [formErrors, setFormErrors] = useState({
      id: '',
      trainingProgram: '',
      trainingTitle: '',
      trainingDescription: '',
      startDate: '',
      endDate: '',
      startTime: '',
      endTime: '',
      department: '',
      area: '',
      trainingType: ''
    });
    const [departments, setDepartments] = useState<any[]>([]); // This will bypass type checking but may lead to other issues.
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
    const [showSecondModal, setShowSecondModal] = useState(false);
    const [formattedTime, setFormattedTime] = useState('');
    const handleCloseSecondModal = () => setShowSecondModal(false);
    const handleSuccessClose = () => setShowSecondModal(false);
    const handleAlertModal = () => setShowAlertModal(false);
    const handleOpenSecondModal = () => {
      setShowFirstModal(false);
      setShowSecondModal(true);
    };
    const [refreshPage, setRefreshPage] = useState(false);
    const [apiError, setApiError] = useState('');
    const [selectedDepartment, setSelectedDepart] = useState({ value: null, label: null });
    const [showAlertModal, setShowAlertModal] = useState(false)
    const [showSuccessAlertModal, setShowSuccessAlertModal] = useState(false)
    const [tooltip, setTooltip] = useState({ visible: false, content: '', left: 0, top: 0 });
    // console.log('selectedDate2>>',selectedDate)
    const [calenderDate, setCalenderDate] = useState(selectedDate);
    const calendarRef: any = useRef(null);
    const [refresh, setRefresh] = useState(false);


    const handleSelectChange = (name: any, selectedOption: any) => {
      //  console.log('here in clicked...>>',name,selectedOption);

      setSelectedDepart({ value: selectedOption?.value, label: selectedOption?.label })
      setFormData({ ...formData, departmentId: selectedOption?.value });
    };


    const fetchTrainings = async (dateRange: { fromDate: string; toDate: string; dateType: string }, departmentIds: any = null) => {
      try {
        const dayRequest = {
          type: "training",
          // userId: 37,
          dateType: dateRange.dateType,
          fromDate: dateRange.fromDate,
          toDate: dateRange.toDate,
          departmentIds: departmentIds || []
        };

        const response: any = await fetchTrainingsListByDateRange(dayRequest);

        if (response?.status === 200) {
          //   console.log(response?.data?.data) 

          setTrainingList(response?.data?.data);
        } else {
          setError(response?.data?.message || 'Error fetching training list');
        }

      } catch (error) {
        console.error("Error fetching training list:", error);
        setError('Error fetching training list');
      }
    };

    const handleEventClick = (clickedEvent: any) => {
      const extendedProps = clickedEvent.extendedProps || {};

      const isTrainingEvent = extendedProps.trainingType === 'training' || clickedEvent.event.title === 'Strength Training';
      const [hrs, mins] = clickedEvent.event.extendedProps.PreviousStartTime.split(':')
      const [hrs2, mins2] = clickedEvent.event.extendedProps.PreviousEndTime.split(':')

      const updatedFormData = {
        ...formData,
        id: clickedEvent.event.id || '',
        trainingType: isTrainingEvent ? 'training' : 'training',
        trainingTitle: clickedEvent.event.extendedProps.trainingTitle || '',
        trainingProgram: clickedEvent.event.extendedProps.trainingProgram || '',
        trainingDescription: clickedEvent.event.extendedProps.description || '',
        startDate: clickedEvent.event.extendedProps.startDate ? new Date(clickedEvent.event.extendedProps.startDate) : null,
        endDate: clickedEvent.event.extendedProps.endDate ? new Date(clickedEvent.event.extendedProps.endDate) : null,
        startTime: `${hrs}:${mins}` || '',
        endTime: `${hrs2}:${mins2}` || '',
        departmentName: clickedEvent.event.extendedProps.departmentName || '',
        area: clickedEvent.event.extendedProps.area || '',
        departmentId: clickedEvent.event.extendedProps.departmentId || '',
      };

      // console.log('updatedFormData>>',updatedFormData)
      setSelectedDepart({ value: updatedFormData?.departmentId, label: updatedFormData?.departmentName })
      setFormData(updatedFormData);

      const currentDateTime = new Date();

      // Check if startDate is not null before proceeding
      if (updatedFormData.startDate) {
        const [hours, minutes] = updatedFormData.startTime.split(':').map(Number);

        // Set the combined start date and time
        const combinedStartDateTime = new Date(updatedFormData.startDate);
        combinedStartDateTime.setHours(hours, minutes);

        // Enable or disable button based on the comparison
        setIsButtonEnabled(combinedStartDateTime > currentDateTime);
      } else {
        // If startDate is null, disable the button
        setIsButtonEnabled(false);
      }

      // console.log(updatedFormData, '-<<formData');
      setShowFirstModal(true);
      setApiError('')
    };


    const handleCloseFirstModal = () => {
      setShowFirstModal(false);
    };

    const handleSuccessCancelModal = () => {
      setShowSuccessAlertModal(false);
    };

    const handleCancelTraining = async () => {
      setShowFirstModal(false);
      setShowAlertModal(true);
      setError('');

    }

    const cancelTraining = async () => {
      try {

        const payload = {
          trainingId: formData.id
        }
        const response: any = await cancelTrainingApi(payload);

        if (response?.status == 200) {
          // setDepartments(response?.data?.data)
          setShowAlertModal(false);
          setShowSuccessAlertModal(true);
          setRefresh(prev => !prev); // Toggle the refresh state
        } else if (response?.status == 400) {
          setError(response?.data?.data || 'error');
        } else if (response?.status == 500) {
          setError('Internal server error');
        } else {
          setError('Internal server error');
        }

      } catch (error) {
        console.error("Error fetching coach list:", error);
      }

    }


    const generateEventsForSingleDay = (events: any): any[] => {

      //console.log('events>>',events)
      return events.map((event: any) => {
        const attendanceColor = getColorForAttendanceStatus(event.status, event.attendanceStatus);

        // console.log(event.trainingTitle, '-<<event');
        // let startDateVal:any=event.startDate.split('T')
        // console.log('startDateVal>>',event.startDate.split('T')[0],event.startTime)
        const returnData = {
          id: event.id,
          trainingType: event.trainingType,
          trainingTitle: event.trainingTitle,
          trainingProgram: event.trainingProgram,
          trainingDescription: event.trainingDescription,
          describe: event.trainingDescription,
          departmentName: event.departmentName,
          startDate: event.startDate,
          endDate: event.endDate,
          PreviousStartTime: event.startTime,
          PreviousEndTime: event.endTime,
          area: event.area_location,
          departmentId: event.departmentId,
          title: event.trainingTitle,
          start: `${event.startDate.split('T')[0]}T${event.startTime}`,
          end: `${event.endDate.split('T')[0]}T${event.endTime}`,
          // start: '2024-10-25T10:00:00',
          // end: '2024-10-28T11:00:00',
          description: event.trainingDescription,
          backgroundColor: attendanceColor,
          borderColor: attendanceColor,
          status: event.status,
          attendanceStatus: event.attendanceStatus
        };

        // console.log(returnData, '-<<returnData');

        return returnData
      });

      // return [
      //   {
      //     title: 'Meeting',
      //     start: '2024-10-25T10:00:00',
      //     end: '2024-10-28T11:00:00',
      //   },
      //   {
      //     title: 'Lunch',
      //     start: '2024-10-26T12:00:00',
      //     end: '2024-10-26T13:00:00',
      //   },
      // ]

    };


    const getColorForAttendanceStatus = (status: string, attendanceStatus: string): string => {
      // console.log(attendanceStatus, '-<,attendanceStatus');

      // Check only for "canceled" status
      if (status === "canceled") {
        return "rgba(232,21,55,0.37)"; // red
      }

      // For all other statuses, check attendanceStatus
      switch (attendanceStatus) {
        case "Upcoming":
          return "rgba(49,179,218,0.37)"; // blue
        case "Completed":
          return "rgba(94,178,22,0.45)"; // green 
        case "Absent":
          return "rgba(221,125,14,0.22)"; // yellow
        default:
          return "rgba(78,87,199,0.46)" //purple  
      }
    };



    const handleDatesSet = (arg: DatesSetArg) => {
      const viewType = arg.view.type;
      const startDate = new Date(arg.startStr);
      const endDate = new Date(arg.endStr);

      let dateRange = {
        fromDate: '',
        toDate: '',
        dateType: ''
      };

      // For month view
      if (viewType === 'dayGridMonth') {
        dateRange = {
          fromDate: format(startDate, 'yyyy-MM-dd'),
          toDate: format(endDate, 'yyyy-MM-dd'),
          dateType: 'month'
        };
      }
      // For week view
      else if (viewType === 'timeGridWeek') {
        dateRange = {
          fromDate: format(startDate, 'yyyy-MM-dd'),
          toDate: format(endDate, 'yyyy-MM-dd'),
          dateType: 'week'
        };
      }
      // For day view
      else if (viewType === 'timeGridDay') {
        dateRange = {
          fromDate: format(startDate, 'yyyy-MM-dd'),
          toDate: format(endDate, 'yyyy-MM-dd'),
          dateType: 'day'
        };
      }

      fetchTrainings(dateRange);  // Fetch trainings based on the new date range
      setCurrentView(viewType);   // Update the current view
      setCurrentDate(startDate);  // Update the current date
    };

    const generatedEvents = generateEventsForSingleDay(trainingList);

    const optionsDepartment = departments.map((el: any) => ({
      value: el.id,
      label: el.departmentName,
    }));



    const fetchDepartmentsList = async () => {
      try {
        const response: any = await fetchDepartment();

        if (response?.status == 200) {
          setDepartments(response?.data?.data)

        } else if (response?.status == 400) {
          setError(response?.data?.message || 'error');
        } else {
          setError(response?.data?.message || 'error');
        }

      } catch (error) {
        console.error("Error fetching coach list:", error);
      }
    };


    const validateForm = (
      formData: {
        type: string;
        trainingProgram: string;
        trainingTitle: string;
        trainingDescription: string;
        department: any;
        area: string;
        startDate: string | null;
        endDate: string | null;
        startTime: string;
        endTime: string;
      },
      setFormErrors: any
    ) => {
      let errors: Record<string, string> = {};

      // Check for empty fields and add error messages
      if (!formData.type) {
        errors.type = 'Training Type is required';
      }
      if (!formData.trainingProgram || formData.trainingProgram.trim() === '') {
        errors.trainingProgram = 'Training Program is required';
      }
      if (!formData.trainingTitle || formData.trainingTitle.trim() === '') {
        errors.trainingTitle = 'Training Title is required';
      } else if (formData.trainingTitle.length <= 3) {
        errors.trainingTitle = 'Training Title must be more than 3 characters';
      }
      if (!formData.trainingDescription || formData.trainingDescription.trim() === '') {
        errors.trainingDescription = 'Training Description is required';
      } else if (formData.trainingDescription.length <= 3) {
        errors.trainingDescription = 'Training Description must be more than 3 characters';
      }

      if (!formData.department) {
        errors.department = 'Department is required';
      }


      if (!formData.area || formData.area.trim() === '') {
        errors.area = 'Area & Location is required';
      }


      if (!formData.startDate) {
        errors.startDate = 'Start Date is required';
      }


      // Validate End Date
      if (!formData.endDate) {
        errors.endDate = 'End Date is required';
      } else {
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Reset time to the start of the day
        const endDate = new Date(formData.endDate);
        if (endDate <= today) {
          errors.endDate = 'End Date cannot be in the past';
        } else if (formData.startDate && new Date(formData.startDate) > endDate) {
          errors.endDate = 'End Date must be after the Start Date';
        }
      }

      if (!formData.startTime || formData.startTime.trim() === '') {
        errors.startTime = 'Start Time is required';
      }
      if (!formData.endTime || formData.endTime.trim() === '') {
        errors.endTime = 'End Time is required';
      }

      // Validate Start Time
      if (formData.startTime) {

        if (formData.startDate && new Date(formData.startDate) > new Date()) {
          //  console.log('start date is greater')
        }
        else {
          // console.log('start date is lesser')
          if (formData.startTime < formattedTime) {
            errors.startTime = 'Start time cannot be in the past';
          }

        }


      }

      // Validate End Time
      if (formData.endTime) {

        const startDate = new Date(formData.startDate!); // Non-null assertion since we check for null above
        // console.log('startDate>',startDate)
        const endTime = new Date(`${startDate.toISOString().split('T')[0]}T${formData.endTime}:00`);
        // console.log('endTime>>',endTime)
        const startTime = new Date(`${startDate.toISOString().split('T')[0]}T${formData.startTime}:00`);

        //console.log('formData.startTime>>',endTime,startTime,formData.endTime,formData.startTime)
        // console.log('formData.startDate>>',new Date(),formData.startDate)

        if (formData.endDate && new Date(formData.endDate) > new Date()) {
          // if (endTime <= startTime) {
          //   errors.endTime = 'End time must be after the Start time';
          // }
          if (formData.endTime <= formData.startTime) {
            errors.endTime = 'End time must be after the Start time';
          }
        }
        else {
          //  console.log('start date is lesser')
          if (formData.endTime < formattedTime) {
            errors.endTime = 'End time cannot be in the past';
          }
          else if (formData.startTime) {
            if (endTime < startTime) {
              errors.endTime = 'End time must be after the Start time';
            }
          }

        }

      }

      setFormErrors(errors);
      return Object.keys(errors).length === 0;
    };


    const handleSubmit = async () => {
      const processedFormData = {
        ...formData,
        trainingProgram: formData.trainingProgram ?? '',
        trainingTitle: formData.trainingTitle ?? '',
        trainingDescription: formData.trainingDescription ?? '',
        department: formData.departmentName ?? '',
        // department: typeof formData.department === 'string' ? formData.department : formData.department?.name ?? '',
        area: formData.area ?? '',
        // startDate: formData.startDate ? formData.startDate.toISOString().slice(0, 10) : null, // Convert Date to string
        startDate: formData.startDate ? new Date(formData.startDate).toString() : null, // Convert Date to string
        // endDate: formData.endDate ? formData.endDate.toISOString().slice(0, 10) : null, // Convert Date to string
        endDate: formData.endDate ? new Date(formData.endDate).toString() : null, // Convert Date to string
        startTime: formData.startTime ?? '',
        endTime: formData.endTime ?? '',
        type: formData.trainingType ?? '',
        departmentId: formData.departmentId ?? ''
      };

      // Validate the form before submitting
      if (!validateForm(processedFormData, setFormErrors)) return;


      const payload = {
        trainingId: formData.id || '',
        trainingType: formData.trainingType || '',
        trainingProgram: processedFormData.trainingProgram,
        trainingTitle: processedFormData.trainingTitle,
        trainingDescription: processedFormData.trainingDescription,
        // departmentName: processedFormData.department,
        area_location: processedFormData.area,
        // startDate: processedFormData.startDate, // Already in correct string format
        // endDate: processedFormData.endDate,     // Already in correct string format
        startDate: moment(processedFormData.startDate).format('YYYY-MM-DD'),
        endDate: moment(processedFormData.endDate).format('YYYY-MM-DD'),
        startTime: processedFormData.startTime,
        endTime: processedFormData.endTime,
        departmentId: processedFormData.departmentId
      };

      console.log(payload, '-<<update payload');



      try {
        const response = await updateTraining(payload);

        if (response?.status == 200) {
          setFormErrors({
            id: '',
            trainingProgram: '',
            trainingTitle: '',
            trainingDescription: '',
            startDate: '',
            endDate: '',
            startTime: '',
            endTime: '',
            department: '',
            area: '',
            trainingType: ''
          })

          setShowFirstModal(false);
          setShowSecondModal(true);
          setRefreshPage(refreshPage => !refreshPage)

        } else if (response?.status == 400) {
          setFormErrors(response?.data?.message || 'error');
        } else {
          setFormErrors(response?.data?.message || 'error');
        }

      } catch (error: any) {
        // Handle error
        console.error("Error creating training:", error);
        setApiError(error?.data?.data[0] || '')
      }
    };

    useEffect(() => {

      // console.log('calender useEffect')
      const currentTimeFormat = getFormattedCurrentTime();
      setFormattedTime(currentTimeFormat)

      // Ensure we use the correct month when fetching initially
      const today = new Date();
      const monthStart = startOfMonth(today);
      const monthEnd = endOfMonth(today);

      fetchTrainings({
        fromDate: format(monthStart, 'yyyy-MM-dd'),
        toDate: format(monthEnd, 'yyyy-MM-dd'),
        dateType: 'month'
      });
      fetchDepartmentsList()


      if (selectedDepartments) {

        const departmentIds: any = selectedDepartments.map((el: any) => el.id);
        // console.log(selectedDepartments, '-<sdkjhfksajgd in callender');

        // console.log(departmentIds, '-<departmentIds in callender');

        const currentTimeFormat = getFormattedCurrentTime();
        setFormattedTime(currentTimeFormat)

        // Ensure we use the correct month when fetching initially
        const today = new Date();
        const monthStart = startOfMonth(today);
        const monthEnd = endOfMonth(today);

        fetchTrainings({
          fromDate: format(monthStart, 'yyyy-MM-dd'),
          toDate: format(monthEnd, 'yyyy-MM-dd'),
          dateType: 'month',
        },
          departmentIds
        );
        fetchDepartmentsList()
      }

      return () => {
        if (tooltipInstance) {
          tooltipInstance.dispose();
          tooltipInstance = null; // Clear the reference
        }
      };

    }, [refreshPageStatus, refreshPage, showSuccessAlertModal, selectedDepartments, calenderDate, refresh]); // Only run on initial load

    let tooltipInstance: any = null;


    const handleEventMouseEnter = (info: any) => {
      if (info.event.extendedProps) {
        let trainingStatus: string = info.event.extendedProps.attendanceStatus === 'Absent' ? 'Missed' : info.event.extendedProps.attendanceStatus;

        // If a tooltip already exists, destroy it
        if (tooltipInstance) {
          tooltipInstance.dispose();
        }

        tooltipInstance = new Tooltip(info.el, {
          title: `Training is ${trainingStatus}`,
          html: true,
          placement: "top",
          trigger: "hover",
          container: "body"
        });

        tooltipInstance.show();
      }
    };

    const handleEventMouseLeave = () => {
      // Hide the tooltip when mouse leaves
      if (tooltipInstance) {
        tooltipInstance.dispose(); // Destroy the tooltip instance
        tooltipInstance = null; // Clear the reference
      }
    };


    const childFunction = (dateVal: string) => {

      const calendarApi = calendarRef.current?.getApi();
      if (calendarApi) {
        const date: any = new Date(dateVal); // Convert the input string to a Date object
        if (!isNaN(date)) { // Check if the date is valid
          calendarApi.gotoDate(date);
        } else {
          alert('Invalid date format. Please use YYYY-MM-DD.');
        }
      }
    };

    useImperativeHandle(ref, () => ({
      callChildFunction: childFunction,
    }));

    return (
      <>

        <div className="calendar-container .fc-h-event .fc-event-main position-relative">
          {/* <button onClick={goToNextMonth}>Go to Next Month</button> */}
          <FullCalendar
            ref={calendarRef}
            plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
            initialView={currentView}
            headerToolbar={{
              left: 'prev,next,title',
              center: '',
              right: 'dayGridMonth,timeGridWeek,timeGridDay',
            }}
            events={generatedEvents}
            eventClick={handleEventClick}
            eventDisplay="block"
            dayMaxEvents={3}  // Show only 3 events and then "+more" link
            moreLinkClick="popover"
            datesSet={handleDatesSet}
            eventMouseEnter={handleEventMouseEnter}
            eventMouseLeave={handleEventMouseLeave}
            date={selectedDate}

          />

          {error && <p className="error-message">{error}</p>}


        </div>

        {/* First Modal */}
        <Modal className="mw-630" show={showFirstModal} onHide={handleCloseFirstModal}>
          <Modal.Header closeButton>
            {/* <Modal.Title>Update Training or Event</Modal.Title> */}
            <Modal.Title>Update Training</Modal.Title>
          </Modal.Header>
          <Modal.Body className="pb-0">
            {/* <div className="inline-fields-wrap mt-0 mb-4">
            <div className="custom-check-field custom-radio fw-700">
              <input
                type="radio"
                name="trainingType"
                value="training"
                checked={formData.trainingType === "training"}
                onChange={(e) => setFormData({ ...formData, trainingType: e.target.value })}
              />
              <label>Training</label>
            </div>
            <div className="custom-check-field custom-radio fw-700">
              <input
                type="radio"
                name="trainingType"
                value="event"
                checked={formData.trainingType === "event"}
                onChange={(e) => setFormData({ ...formData, trainingType: e.target.value })}
              />
              <label>Event</label>
            </div>
          </div> */}

            <Form>
              {/* Training Program */}
              <div className="form-group">
                <label>Training Program</label>
                <div className="custom-select-wrap">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter training program"
                    value={formData.trainingProgram || ''}
                    onChange={(e) => handleSelectChange('trainingProgram', e.target.value)}
                  />
                  {formErrors.trainingProgram && (
                    <div className="text-danger">{formErrors.trainingProgram}</div>
                  )}
                </div>
              </div>

              {/* Training Title */}
              <div className="form-group">
                <label>Training Title</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Training Title"
                  value={formData.trainingTitle}
                  onChange={(e) => setFormData({ ...formData, trainingTitle: e.target.value })}
                />
                {formErrors.trainingTitle && (
                  <div className="text-danger">{formErrors.trainingTitle}</div>
                )}
              </div>

              {/* Training Description */}
              <Form.Group className='form-group' controlId="formTrainingDescription">
                <Form.Label>Training Description</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Enter Training Description"
                  value={formData.trainingDescription}
                  onChange={(e) => setFormData({ ...formData, trainingDescription: e.target.value })}
                  isInvalid={!!formErrors.trainingDescription}
                />
                <Form.Control.Feedback type="invalid">
                  {formErrors.trainingDescription}
                </Form.Control.Feedback>
              </Form.Group>

              {/* Date and Time Fields */}
              {/* <Row>
                <Col lg="6">
                  <Form.Group className='form-group' controlId="formStartDate">

                    <Form.Label>Start Date</Form.Label>
                    <MyDatePicker
                      value={formData.startDate || null}
                      onChange={(date) => {
                        if (date instanceof Date && !isNaN(date.getTime())) {
                          const normalizedDate = new Date(date);
                          normalizedDate.setHours(0, 0, 0, 0);
                          setFormData({ ...formData, startDate: normalizedDate });
                        }
                      }}
                    />
                    {formErrors.startDate && <div className="text-danger">{formErrors.startDate}</div>}
                  </Form.Group>
                </Col>
                <Col lg="6">
                  <Form.Group className='form-group' controlId="formEndDate">
                    <Form.Label>End Date</Form.Label>
                    <MyDatePicker
                      value={formData.endDate || null}
                      onChange={(date) => setFormData({ ...formData, endDate: date })}
                    />
                    {formErrors.endDate && <div className="text-danger">{formErrors.endDate}</div>}
                  </Form.Group>
                </Col>
              </Row> */}

              <Row>
                <Col lg="6">
                  <Form.Group className='form-group' controlId="formStartDate">
                    <Form.Label>Start Date</Form.Label>
                    <MyDatePicker
                      value={formData.startDate || null}
                      onChange={(date) => {
                        if (date instanceof Date && !isNaN(date.getTime())) {
                          const normalizedDate = new Date(date);
                          normalizedDate.setHours(0, 0, 0, 0);
                          // Clear endDate when startDate is modified
                          setFormData({ ...formData, startDate: normalizedDate, endDate: null });
                        }
                      }}
                    />
                    {formErrors.startDate && <div className="text-danger">{formErrors.startDate}</div>}
                  </Form.Group>
                </Col>
                <Col lg="6">
                  <Form.Group className='form-group' controlId="formEndDate">
                    <Form.Label>End Date</Form.Label>
                    <MyDatePicker
                      value={formData.endDate || null}
                      onChange={(date) => {
                        if (date instanceof Date && !isNaN(date.getTime())) {
                          setFormData({ ...formData, endDate: date });
                        } else {
                          setFormData({ ...formData, endDate: null }); // Clear endDate if invalid date
                        }
                      }}
                    />
                    {formErrors.endDate && <div className="text-danger">{formErrors.endDate}</div>}
                  </Form.Group>
                </Col>
              </Row>


              <Row>
                <Col lg="6">
                  <Form.Group className='form-group' controlId="formStartTime">
                    <Form.Label>Start Time</Form.Label>
                    <div className="iconFields">
                      <Form.Control
                        type="time"
                        name="startTime"
                        value={formData.startTime}
                        onChange={(e) => {
                          const newStartTime = e.target.value; // Get the new start time
                          setFormData({ ...formData, startTime: newStartTime, endTime: '' }); // Clear end time
                        }}
                      />
                      <i className="icon icon-clock fw-400"></i>
                    </div>
                    {formErrors.startTime && <div className="text-danger">{formErrors.startTime}</div>}
                  </Form.Group>
                </Col>
                <Col lg="6">
                  <Form.Group className='form-group' controlId="formEndTime">
                    <Form.Label>End Time</Form.Label>
                    <div className="iconFields">
                      <Form.Control
                        type="time"
                        name="endTime"
                        value={formData.endTime}
                        onChange={(e) => setFormData({ ...formData, endTime: e.target.value })}
                      />
                      <i className="icon icon-clock fw-400"></i>
                    </div>
                    {formErrors.endTime && <div className="text-danger">{formErrors.endTime}</div>}
                  </Form.Group>
                </Col>
              </Row>


              {/* Department */}
              <Form.Group className='form-group' controlId="formDepartment">
                <Form.Label>Department</Form.Label>

                <div className='custom-select-wrap'>
                  <Select
                    className="selectpicker"
                    placeholder="Select department"
                    options={departments.map((department) => ({
                      value: department.id,
                      label: department.departmentName,
                    }))}
                    value={selectedDepartment}
                    onChange={(selectedOption) =>
                      handleSelectChange('department', selectedOption)
                    }
                  />
                </div>
                {formErrors.department && (
                  <div className="text-danger">{formErrors.department}</div>
                )}
              </Form.Group>

              {/* Area & Location */}
              <Form.Group className='form-group' controlId="formAreaLocation">
                <Form.Label>Area & Location</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Area & Location"
                  value={formData.area || ''}
                  onChange={(e) => handleSelectChange('area', e.target.value)}
                />
                {formErrors.area && <div className="text-danger">{formErrors.area}</div>}
              </Form.Group>
            </Form>
            {apiError && apiError != '' && <div className="text-danger">{apiError}</div>}
          </Modal.Body>
          <Modal.Footer>
            {/* <Button variant="secondary" onClick={handleCloseFirstModal}>
            Close
          </Button> */}
            <Button variant="danger" onClick={handleCancelTraining} disabled={!isButtonEnabled}>
              Cancel Training
            </Button>
            <Button variant="primary" onClick={handleSubmit} disabled={!isButtonEnabled}>
              Update Training
            </Button>
          </Modal.Footer>
        </Modal>


        {/* Second Modal */}
        <Modal className="mw-630" show={showSecondModal} onHide={handleCloseSecondModal}>
          <Modal.Header closeButton>
            <Modal.Title>Update Training or Event</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="successBlock">
              <div className="ico">
                <i className="icon-success"></i>
              </div>
              <div className="cont">
                <p> Training updated successfully.</p>
              </div>
            </div>
            <div className="teBlock mt-2">
              <div className="cont">
                <div className="label">{formData.trainingTitle}</div>
                <div className="value fw-700"> {formData.trainingDescription} </div>
              </div>
              <div className="cont">
                <div className="label">Training Date</div>
                {/* <div className="value fw-700">12/07/2024 - 14/07/2024</div> */}
                <div className="value fw-700">
                  {formData.startDate ? new Date(formData.startDate).toLocaleDateString() : 'N/A'} -
                  {formData.endDate ? new Date(formData.endDate).toLocaleDateString() : 'N/A'}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="justify-content-center bt-0 pt-0 pb-4">
            <Button variant="primary" onClick={handleCloseSecondModal}>Okay</Button>
          </Modal.Footer>
        </Modal>

        {/* Alert modal */}
        <Modal className="mw-630" show={showAlertModal} onHide={handleAlertModal}>
          <Modal.Header closeButton>
            <Modal.Title>Cancel Training</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="successBlock">
              <div className="ico">
                <i className="icon-success"></i>
              </div>
              <div className="cont">
                <p>Are you sure you want to cancel Training</p>
              </div>
            </div>
            <div className="teBlock mt-2">
              <div className="cont">
                <div className="label">{formData.trainingTitle}</div>
                <div className="value fw-700"> {formData.trainingDescription} </div>
              </div>
              <div className="cont">
                <div className="label">Training Date</div>
                {/* <div className="value fw-700">12/07/2024 - 14/07/2024</div> */}
                <div className="value fw-700">
                  {formData.startDate ? new Date(formData.startDate).toLocaleDateString() : 'N/A'} -
                  {formData.endDate ? new Date(formData.endDate).toLocaleDateString() : 'N/A'}
                </div>
                <div className="value fw-700">
                  {formData.startTime ? formData.startTime : 'N/A'} -
                  {formData.endTime ? formData.endTime : 'N/A'}
                </div>
              </div>
            </div>
            {error && <p className="text-danger" style={{ textAlign: 'center', margin: '0 auto' }}>{error}</p>}

          </Modal.Body>
          <Modal.Footer className="justify-content-center bt-0 pt-0 pb-4">
            <Button variant="danger" onClick={cancelTraining}>Cancel</Button>


          </Modal.Footer>

        </Modal>



        {/* success cancel*/}
        <Modal className="mw-630" show={showSuccessAlertModal} onHide={handleSuccessCancelModal}>
          <Modal.Header closeButton>
            <Modal.Title>Cancel Training Success</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="successBlock">
              <div className="ico">
                <i className="icon-success"></i>
              </div>
              <div className="cont">
                <p>Training has been Cancelled</p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="justify-content-center bt-0 pt-0 pb-4">
            <Button variant="primary" onClick={handleSuccessCancelModal}>Close</Button>
          </Modal.Footer>
        </Modal>


      </>
    );
  });

export default CalendarComponent;