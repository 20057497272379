import { Card, Col, Row } from "@govtechsg/sgds-react";
import { useState, useEffect, SetStateAction } from 'react';
import SideNavBar from "../../components/Sidebar-left/sideBar";
import TopNav from "../../components/top-nav";
import Footer from "../../components/footer";
import { Container, Dropdown, Pagination, Modal, Button } from "react-bootstrap";
import { jwtDecode } from 'jwt-decode'; // Fix: jwtDecode should be imported without curly braces
import { useMsal } from "@azure/msal-react";
import { useForm } from "react-hook-form";
import { addRecord, fetchDepartment, fetchAssessments, updateAssessmentRecord, getAssessmentRecord, deleteAssessment } from "../../service/common.service";
import { isTokenValid } from "../../utils/TokenValid";
import { useNavigate } from "react-router-dom";

import { aS } from "@fullcalendar/core/internal-common";

const Assessments = () => {

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm({ mode: "onChange" });

  const [formData, setFormData] = useState({
    title: "",
    category: "",
    departmentId: "",
    description: "",
  });

  const { instance, accounts } = useMsal();

  const [show, setShow] = useState(false);
  const [editShow, seteditShow] = useState(false);

  const handleClose = () => {
    setFormData({
      title: "",
      category: "",
      departmentId: "",
      description: "",
    })
    setShow(false);
    seteditShow(false);
  }
  const handleShow = () => {
    // setFormData({
    //   title: "Raja",
    //   category: "",
    //   departmentId: "",
    //   description:"",
    // })
    setRefreshPage(refreshPage => !refreshPage)
    setShow(true);
  };

  const [departments, setDepartments] = useState([]);
  const [assessments, setAssessments] = useState([]);
  const [apiError, setApiError] = useState('')
  const [refreshPage, setRefreshPage] = useState(false)
  const [order, setOrder] = useState("DESC");
  const [limits, setlimits] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, settotalCount] = useState(0);
  const [totalSearchCount, setSearchtotalCount] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [formDataId, setFormDataId] = useState('');
  const navigate = useNavigate();

  // console.log(instance, accounts);

  useEffect((): any => {
    // const accessToken = localStorage.getItem("accessToken");

    // if (!accessToken) {
    //   navigate("/auth");
    // }
    // // Check if token is valid on initial load
    // if (!isTokenValid(accessToken)) {
    //   // If token is invalid, redirect to login
    //   navigate("/auth");
    // }

    const departmentList = async () => {
      try {
        const response = await fetchDepartment();  // Assuming this is the API call
        console.log(response); // Log the full response to inspect its structure

        // Check if the response is an object and contains a valid 'data' array
        if (response?.status === 200 && Array.isArray(response?.data?.data)) {
          setDepartments(response?.data?.data);  // Assuming departments data is under response.data.data
        } else {
          console.error("Invalid response format");
          setDepartments([]);  // Set departments to empty array if invalid response
        }
      } catch (error) {
        console.error("Error fetching department list:", error);
        setDepartments([]);  // Set departments to empty array if an error occurs
      }
    };

    const assessmentList = async () => {
      try {
        let dataRequest = {
          "page": currentPage,
          "limit": limits,
          "order": order,
          "search": searchTerm
        }
        const response: any = await fetchAssessments(dataRequest);
        if (response?.status == 200) {
          setAssessments(response?.data?.data?.listItems);
          settotalCount(response?.data?.data?.totalCount);
          setSearchtotalCount(response?.data?.data?.totalSearchAssessnments);
        } else if (response?.status == 400) {
          setApiError(response?.data?.message || 'error');
        } else {
          setApiError(response?.data?.message || 'error');
        }

      } catch (error) {
        console.error("Error fetching Assessments list:", error);
      }
    }

    setFormData({
      title: "",
      category: "",
      departmentId: "",
      description: "",
    })
    setApiError('')
    departmentList();
    assessmentList();
  }, [refreshPage, order, currentPage, limits, searchTerm])
  // console.log(instance.acquireTokenSilent, '-<,acquireTokenSilent');
  useEffect(() => {
    if (formData.title || formData.category || formData.description || formData.departmentId) {
      seteditShow(true);  // Open modal only if formData is populated
    }
  }, [formData]);

  const onSubmit = async (e: any) => {
    // Make sure the form data is correctly parsed
    let payload = {
      ...formData,
      departmentId: parseInt(formData.departmentId), // Ensure the departmentId is a number
    };
    console.log(payload); // Debug: Check payload before submitting
    try {
      let response = await addRecord(payload);
      if (response?.status === 200) {
        setApiError('');  // Clear the error message if the request is successful
        setRefreshPage(!refreshPage);  // Trigger page refresh to show updated data
        setShow(false);
      } else {
        setApiError(response?.data?.message || 'Error occurred');
      }
    } catch (error: any) {
      setApiError(error?.data?.message || 'Error occurred');
    }
  };

  const onEditSubmit = async (e: any) => {
    const payload = {
      ...formData,
      assessmentId: parseInt(formDataId),
      departmentId: parseInt(formData.departmentId),  // Ensure the departmentId is a number
    };
    console.log('payload', payload);
    try {
      const response = await updateAssessmentRecord(payload);  // Call the API to update the record
      if (response?.status === 200) {
        setApiError('');  // Clear any previous error
        setRefreshPage(!refreshPage);  // Trigger page refresh to update data
        seteditShow(false);  // Close the edit modal
      } else {
        setApiError(response?.data?.message || 'Error occurred while updating the assessment');
      }
    } catch (error) {
      console.error("Error updating assessment:", error);
      setApiError('Error occurred while updating the assessment');
    }
  };

  const handleEdit = async (assessmentId: any) => {
    console.log('Editing assessment with ID:', assessmentId);
    seteditShow(false);
    await setFormData({
      title: " ",
      category: " ",
      departmentId: " ",
      description: " ",
    });

    try {
      const response = await getAssessmentRecord({ assessmentId });
      if (response?.status === 200) {
        const assessmentData = response?.data?.data[0];
        setFormDataId(assessmentId);
        // Populate form with the data fetched for editing
        console.log('Editing assessmentData:', assessmentData.title);
        setFormData({
          title: assessmentData?.title,
          category: assessmentData?.category,
          departmentId: assessmentData?.departmentId,
          description: assessmentData?.description,
        });
        seteditShow(true);
        // Open the modal for editing
      } else {
        setApiError(response?.data?.message || 'Error fetching assessment for editing');
      }
    } catch (error) {
      console.error("Error fetching assessment for editing:", error);
      setApiError('Error occurred while fetching assessment details');
    }
  };



  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleChangeUpdate = (e: any) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };


  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };
  const totalPages = Math.ceil(totalSearchCount / limits);
  const handleSearchChange = (e: any) => {
    setSearchTerm(e.target.value);  // Update search term
  };

  const handleDelete = async (assessmentId: number) => {
    const confirmed = window.confirm("Are you sure you want to delete this assessment?");
    if (!confirmed) {
      return;
    }
    try {
      const payload = {
        assessmentId: assessmentId
      };
      const response = await deleteAssessment(payload);
      if (response?.status === 200) {
        setApiError('');
        setRefreshPage(!refreshPage);
      } else {
        setApiError(response?.data?.message || 'Error occurred while deleting the assessment');
      }
    } catch (error) {
      setApiError('Error occurred while deleting the assessment');
      console.error("Error deleting assessment:", error);
    }
  };

  return (
    <>
      <TopNav />
      <div className="d-flex align-items-stretch">
        <SideNavBar />
        <div className="page-content pb-4">
          <div className="bredcrumb">
            <ul>
              <li><a href="#">Home</a></li>
              <li>Assessment</li>
            </ul>
          </div>
          <section className="blockWrap no-padding-bottom p-0">
            <div className="card">
              <div className="card-header d-flex align-items-center justify-content-between">
                <div className="cardTitle">
                  <h5>Assessments</h5>
                </div>

                <button className="btn btn-outline-primary" onClick={handleShow}>Create Assessment <i className="icon-plus-sign"></i></button>
              </div>

              <div className="card-body">
                <div className="filterBar">
                  <div className="title">Total Assessments <span className="theme-color">[{totalCount}]</span></div>
                  <div className="searchBar">
                    {/* <input type="text" placeholder="Search by assessment" value="" /> */}
                    <input
                      type="text"
                      placeholder="Search by assessment"
                      value={searchTerm}
                      onChange={handleSearchChange}  // Update the search term
                    />
                    <button className="searchBtn"><i className="icon-search-sign"></i></button>
                  </div>
                </div>

                <div className="assessmentsBlockWrap">
                  {

                    assessments.map((record: any) => (
                      <div className="assessmentsBlock">
                        <div className="assessmentsBlockLeft">
                          <h5>{record.title}</h5>
                          <div className="dateBlock">
                            <span className="dateBlockIn">Created : <span className="value">{record.createdDate}</span></span>
                            <span className="dateBlockIn">Team : <span className="value">{record.departmentName}</span></span>
                          </div>
                        </div>

                        <div className="assessmentsBlockRight">
                          <div className="assessmentsQuestWrap">
                            <div className="assessmentsQuest">
                              <span className="label">Questions</span>
                              <div className="value">{record.questnsNo}</div>
                            </div>
                            <div className="assessmentsQuest">
                              <span className="label">Assigned</span>
                              <div className="value">{record.assignCount}</div>
                            </div>
                            <div className="assessmentsQuest">
                              <span className="label">Response</span>
                              <div className="value">{record.responseCount}</div>
                            </div>
                          </div>

                          <div className="actionWrap">
                            <button className="graphLink" onClick={handleShow}><span className="icon-graph-v1"><span className="graphIc"></span></span></button>
                            <Dropdown>
                              <Dropdown.Toggle id="dropdown-basic">
                                <i className="icon-dots-v"></i>
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item onClick={() => handleEdit(record.assessmentId)}>Edit</Dropdown.Item>
                                <Dropdown.Item onClick={() => handleDelete(record.assessmentId)}>Delete</Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>

                        </div>
                      </div>

                    ))
                  }
                </div>


                <div className="mt-4 justify-content-center d-flex">
                  <Pagination>
                    <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
                    {[...Array(totalPages)].map((_, idx) => (
                      <Pagination.Item
                        key={idx + 1}
                        active={currentPage === idx + 1}
                        onClick={() => handlePageChange(idx + 1)}
                      >
                        {idx + 1}
                      </Pagination.Item>
                    ))}
                    <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
                  </Pagination>
                </div>

              </div>
            </div>


          </section>

          <Footer />
        </div>
      </div>

      <Modal className="mw-530" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create New Assessment</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">

          <form
            className="ng-untouched ng-pristine ng-invalid"
            //   onSubmit={handleSubmit}
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="form-group">
              <label>Assessment Title</label>
              <input type="text"           {...register("title", { required: "This field is required" })}
                onChange={handleChange} value={formData.title} name="title" placeholder="Enter Assessment Title" />
              {errors.title && (
                <p className="text-danger">This field is required</p>
              )}
            </div>
            <div className="form-group">
              <label>Assessment Category</label>
              <input type="text"         {...register("category", { required: "This field is required" })}
                onChange={handleChange} value={formData.category} placeholder="Enter Assessment Category" />

              {errors.category && (
                <p className="text-danger">This field is required</p>
              )}

            </div>
            <div className="form-group">
              <label>Select Team</label>
              <select
                {...register("departmentId", { required: "This field is required" })}
                onChange={handleChange}
                value={formData.departmentId}  // Bind the value of the select input to formData.departmentId
              >
                <option value="" disabled>Select Team</option>
                {
                  departments.map((record: any) => (
                    <option key={record.id} value={record.id}>
                      {record.departmentName}
                    </option>
                  ))
                }
              </select>

              {errors.departmentId && (
                <p className="text-danger">This field is required</p>
              )}
            </div>

            <div className="form-group">
              <label>Assessment Description </label>
              <textarea         {...register("description", { required: "This field is required" })}
                name="description" onChange={handleChange} value={formData.description} placeholder="Lorem ipsum is placeholder text commonly used in the graphic,
print, and publishing industries for previewing layouts and 
visual mockups."></textarea>
              {errors.description && (
                <p className="text-danger">This field is required</p>
              )}
            </div>

            {apiError != '' && (
              <p className="text-danger">{apiError}</p>
            )}
            <Button variant="primary" type="submit" disabled={!isValid}>
              Create  New Assessment
            </Button>
            <Button variant="outline-primary" className="me-2 yan ms-2" onClick={handleClose}>
              Close
            </Button>
          </form>
        </Modal.Body>
        <Modal.Footer className="bt-0 pt-0 pb-4">
          {/* <Button variant="primary" type="submit"   disabled={!isValid}>
            Create  New Assessment
          </Button> */}


        </Modal.Footer>
      </Modal>

      <Modal className="mw-530" show={editShow} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Assessment</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">

          <form
            className="ng-untouched ng-pristine ng-invalid"
            //   onSubmit={handleSubmit}
            onSubmit={handleSubmit(onEditSubmit)}
          >
            <div className="form-group">
              <label>Assessment Title</label>
              <input type="text"           {...register("title", { required: "This field is required" })}
                onChange={handleChangeUpdate} value={formData.title} name="title" placeholder="Enter Assessment Title" />
              {errors.title && (
                <p className="text-danger">This field is required</p>
              )}
            </div>
            <div className="form-group">
              <label>Assessment Category</label>
              <input type="text"         {...register("category", { required: "This field is required" })}
                onChange={handleChangeUpdate} value={formData.category} placeholder="Enter Assessment Category" />

              {errors.category && (
                <p className="text-danger">This field is required</p>
              )}

            </div>
            <div className="form-group">
              <label>Select Team</label>
              <select
                {...register("departmentId", { required: "This field is required" })}
                onChange={handleChangeUpdate}
                value={formData.departmentId}  // Bind the value of the select input to formData.departmentId
              >
                <option value="" disabled>Select Team</option>
                {
                  departments.map((record: any) => (
                    <option key={record.id} value={record.id}>
                      {record.departmentName}
                    </option>
                  ))
                }
              </select>

              {errors.departmentId && (
                <p className="text-danger">This field is required</p>
              )}
            </div>

            <div className="form-group">
              <label>Assessment Description </label>
              <textarea         {...register("description", { required: "This field is required" })}
                name="description" onChange={handleChangeUpdate} value={formData.description} placeholder="Lorem ipsum is placeholder text commonly used in the graphic,
print, and publishing industries for previewing layouts and 
visual mockups."></textarea>
              {errors.description && (
                <p className="text-danger">This field is required</p>
              )}
            </div>
            {/* <input type="hidden" {...register("assessmentId")} value={formData.assessmentId}  /> */}

            {apiError != '' && (
              <p className="text-danger">{apiError}</p>
            )}
            <Button variant="primary" type="submit" disabled={!isValid}>
              Update Assessment
            </Button>
            <Button variant="outline-primary" className="me-2 yan ms-2" onClick={handleClose}>
              Close
            </Button>
          </form>
        </Modal.Body>
        <Modal.Footer className="bt-0 pt-0 pb-4">
        </Modal.Footer>
      </Modal>

    </>
  );
};

export default Assessments;