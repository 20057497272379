// BarChart.js
import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend,} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);



interface hrzRecordProp {

  hrzRecord:any;
}
const BarChart: React.FC <hrzRecordProp> = ({hrzRecord}) => {

  let dataList:any=hrzRecord.map((record:any)=>{return record.timeSpent})
  const data = {
    labels: ['Very Light Warm Up Zone', 'Light Fat Burn Zone', 'Moderate Aerobic Zone', 'Hard Anaerobic Zone', 'Maximum VO2 Max Zone'],
    datasets: [
        {
            label: 'Time spent',
            data: dataList,
          //   backgroundColor: [
          //     '#B9CCFF', // January
          //     '#F2E4A4', // February
          //     '#A0D16F', // March
          //     '#F2A57B', // April
          //     '#E28282', // May
          // ],

          backgroundColor: [
            '#D9D9D9', // January
            '#3D9DB0', // February
            '#02AE4E', // March
            '#FDD964', // April
            '#FE4F4F', // May
        ],
          
          borderWidth: 0, 
          barPercentage: 1, // Increase bar width
          categoryPercentage: 1,
        },
    ],
};

const options = {
  responsive: true,
  maintainAspectRatio: false, 
  plugins: {
    legend: {
        display: false, // Hide the legend
    },
  },
  layout: {
    padding: {
      left: -10,  // Remove left padding
      right: 0, // Remove right padding
      top: 0,  // Optional: Add a little top padding if needed
      bottom: -10, // Remove bottom padding
    },
  },
  scales: {
      x: {
          grid: {
              display: false, // Hide grid lines on the x-axis
              drawBorder: false,
          },
          title: {
            display: false, // Hide x-axis title
          },
          ticks: {
            display: false, // Hide x-axis tick labels
          },
          
          
      },
      y: {
          beginAtZero: true,
          min: 0,
          max: Math.max(...data.datasets[0].data) + 1,
          grid: {
              display: false, // Hide grid lines on the y-axis
              drawBorder: false,
          },
          title: {
            display: false, // Hide y-axis title
        },
        ticks: {
            display: false, // Hide y-axis tick labels
        },
        border: {
          dash: [], // Ensure no border lines are drawn
          color: 'rgba(0, 0, 0, 0)', // Set the border color to transparent
          width: 0, // Set the border width to 0
      },
      },
  },
};

    return <Bar data={data} options={options}  />;
};

export default BarChart;

